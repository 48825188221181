import { Component, OnInit } from '@angular/core'
import { ToastrService } from 'ngx-toastr'

@Component({
    selector: 'view-seat-component',
    template: `
    <div class="flex flex-col w-auto mt-2 mb-4 border border-black h-auto">
        <!--STERING WHEEL-->
        <div class="flex justify-start items-start h-auto mb-1 w-full">
            <svg class="w-5 m-1 flex " version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
             viewBox="0 0 32 32" xml:space="preserve">
                <g>
                <g id="steering_x5F_wheel">
                    <path style="fill:#030104;" d="M16,0C7.164,0,0,7.164,0,16s7.164,16,16,16s16-7.164,16-16S24.836,0,16,0z M16,4
                        c5.207,0,9.605,3.354,11.266,8H4.734C6.395,7.354,10.793,4,16,4z M16,18c-1.105,0-2-0.895-2-2s0.895-2,2-2s2,0.895,2,2
                        S17.105,18,16,18z M4,16c5.465,0,9.891,5.266,9.984,11.797C8.328,26.828,4,21.926,4,16z M18.016,27.797
                        C18.109,21.266,22.535,16,28,16C28,21.926,23.672,26.828,18.016,27.797z"/>
                </g>
                </g>
            </svg>
        </div>
    <!--BUS SEATS-->
        <div class="flex flex-col w-full h-full">
        <div class="flex w-full justify-between ">
        <!--COLUMN A-->
        <div class="flex w-1/2 mr-1">
            <div class="flex flex-col" *ngFor="let row of seatsA;  let i = index">
                <svg *ngFor="let seat of row;  let i = index" class="w-5 m-1" viewBox="-0.98 0 20.001 20.001" xmlns="http://www.w3.org/2000/svg">
                    <g id="seat-car" transform="translate(-2.983 -1.999)">
                        <path id="secondary"  [attr.fill]="seat.status === 'occupied' ? '#fda5a5' : seat.status === 'selected' ? '#7bf3ab' : '#9ba3ad'" d="M7.38,15a1,1,0,0,1,.9.55A2.61,2.61,0,0,0,10.62,17h2.94a2.61,2.61,0,0,0,2.34-1.45,1,1,0,0,1,.9-.55h1.62L19,8.68a1,1,0,0,0-.55-1L17.06,7l-.81-3.24a1,1,0,0,0-1-.76H8.72a1,1,0,0,0-1,.76L6.94,7l-1.39.69a1,1,0,0,0-.55,1L5.58,15Z"/>
                        <path id="primary" [attr.fill]="seat.status === 'occupied' ? '#fda5a5' : seat.status === 'selected' ? '#7bf3ab' : '#9ba3ad'" d="M5.58,15,5,8.68a1,1,0,0,1,.55-1L6.94,7l.81-3.24a1,1,0,0,1,1-.76h6.56a1,1,0,0,1,1,.76L17.06,7l1.39.69a1,1,0,0,1,.55,1L18.42,15H16.8a1,1,0,0,0-.9.55A2.61,2.61,0,0,1,13.56,17H10.62a2.61,2.61,0,0,1-2.34-1.45,1,1,0,0,0-.9-.55Zm10.32.55h0A2.61,2.61,0,0,1,13.56,17H10.62a2.61,2.61,0,0,1-2.34-1.45h0a1,1,0,0,0-.9-.55H5a1,1,0,0,0-1,1.16l.53,3.17a2,2,0,0,0,2,1.67h11a2,2,0,0,0,2-1.67L20,16.16A1,1,0,0,0,19,15H16.8a1,1,0,0,0-.9.55Z" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    </g>
                </svg>
            </div>
        </div>
         <!--ASIENTO DEL MEDIO-->
        <div class="flex w-1/2"  *ngFor="let row of seatsB.slice(0, 1);  let i = index">
            <div class="flex flex-col justify-end">
                <svg *ngFor="let seat of row.slice(0, 1);  let i = index" class="w-5 m-1" viewBox="-0.98 0 20.001 20.001" xmlns="http://www.w3.org/2000/svg">
                    <g id="seat-car" transform="translate(-2.983 -1.999)">
                        <path id="secondary"  [attr.fill]="seat.status === 'occupied' ? '#fda5a5' : seat.status === 'selected' ? '#7bf3ab' : '#9ba3ad'" d="M7.38,15a1,1,0,0,1,.9.55A2.61,2.61,0,0,0,10.62,17h2.94a2.61,2.61,0,0,0,2.34-1.45,1,1,0,0,1,.9-.55h1.62L19,8.68a1,1,0,0,0-.55-1L17.06,7l-.81-3.24a1,1,0,0,0-1-.76H8.72a1,1,0,0,0-1,.76L6.94,7l-1.39.69a1,1,0,0,0-.55,1L5.58,15Z"/>
                        <path id="primary" [attr.fill]="seat.status === 'occupied' ? '#fda5a5' : seat.status === 'selected' ? '#7bf3ab' : '#9ba3ad'" d="M5.58,15,5,8.68a1,1,0,0,1,.55-1L6.94,7l.81-3.24a1,1,0,0,1,1-.76h6.56a1,1,0,0,1,1,.76L17.06,7l1.39.69a1,1,0,0,1,.55,1L18.42,15H16.8a1,1,0,0,0-.9.55A2.61,2.61,0,0,1,13.56,17H10.62a2.61,2.61,0,0,1-2.34-1.45,1,1,0,0,0-.9-.55Zm10.32.55h0A2.61,2.61,0,0,1,13.56,17H10.62a2.61,2.61,0,0,1-2.34-1.45h0a1,1,0,0,0-.9-.55H5a1,1,0,0,0-1,1.16l.53,3.17a2,2,0,0,0,2,1.67h11a2,2,0,0,0,2-1.67L20,16.16A1,1,0,0,0,19,15H16.8a1,1,0,0,0-.9.55Z" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    </g>
                </svg>
            </div>
        </div>
         <!--COLUMN B-->
        <div class="flex w-1/2 ml-1">
            <div class="flex flex-col" *ngFor="let row of seatsB;  let i = index">
                <svg *ngFor="let seat of row;  let i = index" class="w-5 m-1" viewBox="-0.98 0 20.001 20.001" xmlns="http://www.w3.org/2000/svg">
                    <g id="seat-car" transform="translate(-2.983 -1.999)">
                        <path id="secondary"  [attr.fill]="seat.status === 'occupied' ? '#fda5a5' : seat.status === 'selected' ? '#7bf3ab' : '#9ba3ad'" d="M7.38,15a1,1,0,0,1,.9.55A2.61,2.61,0,0,0,10.62,17h2.94a2.61,2.61,0,0,0,2.34-1.45,1,1,0,0,1,.9-.55h1.62L19,8.68a1,1,0,0,0-.55-1L17.06,7l-.81-3.24a1,1,0,0,0-1-.76H8.72a1,1,0,0,0-1,.76L6.94,7l-1.39.69a1,1,0,0,0-.55,1L5.58,15Z"/>
                        <path id="primary" [attr.fill]="seat.status === 'occupied' ? '#fda5a5' : seat.status === 'selected' ? '#7bf3ab' : '#9ba3ad'" d="M5.58,15,5,8.68a1,1,0,0,1,.55-1L6.94,7l.81-3.24a1,1,0,0,1,1-.76h6.56a1,1,0,0,1,1,.76L17.06,7l1.39.69a1,1,0,0,1,.55,1L18.42,15H16.8a1,1,0,0,0-.9.55A2.61,2.61,0,0,1,13.56,17H10.62a2.61,2.61,0,0,1-2.34-1.45,1,1,0,0,0-.9-.55Zm10.32.55h0A2.61,2.61,0,0,1,13.56,17H10.62a2.61,2.61,0,0,1-2.34-1.45h0a1,1,0,0,0-.9-.55H5a1,1,0,0,0-1,1.16l.53,3.17a2,2,0,0,0,2,1.67h11a2,2,0,0,0,2-1.67L20,16.16A1,1,0,0,0,19,15H16.8a1,1,0,0,0-.9.55Z" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    </g>
                </svg>
            </div>
        </div>
    </div>
        </div>
    </div>

    `,
    styles: []
})

export class MySeatComponent implements OnInit {
   
   
    constructor(private toastr: ToastrService) { }
    seatsA = [
        [
            { id: 'A101', status: 'occupied', selected: false },
            { id: 'A201', status: 'occupied', selected: false },
            { id: 'A301', status: 'occupied', selected: false },
            { id: 'A401', status: 'selected', selected: false },
            { id: 'A501', status: 'occupied', selected: false },
            { id: 'A601', status: 'occupied', selected: false },
            { id: 'A701', status: 'occupied', selected: false },
            { id: 'A801', status: 'occupied', selected: false },
            { id: 'A901', status: 'occupied', selected: false },
            { id: 'A1001', status: 'occupied', selected: false },
            { id: 'A1101', status: 'occupied', selected: false },
            { id: 'A1201', status: 'occupied', selected: false },
          ],
          [
              { id: 'A102', status: 'occupied', selected: false },
              { id: 'A202', status: 'occupied', selected: false },
              { id: 'A302', status: 'occupied', selected: false },
              { id: 'A402', status: 'selected', selected: false },
              { id: 'A502', status: 'occupied', selected: false },
              { id: 'A602', status: 'occupied', selected: false },
              { id: 'A702', status: 'occupied', selected: false },
              { id: 'A802', status: 'occupied', selected: false },
              { id: 'A902', status: 'occupied', selected: false },
              { id: 'A1002', status: 'occupied', selected: false },
              { id: 'A1102', status: 'occupied', selected: false },
              { id: 'A1202', status: 'occupied', selected: false },
            ],
    ]
    seatsB = [
        
          [
            { id: 'B101', status: 'occupied', selected: false },
            { id: 'B201', status: 'occupied', selected: false },
            { id: 'B301', status: 'occupied', selected: false },
            { id: 'B401', status: 'occupied', selected: false },
            { id: 'B501', status: 'occupied', selected: false },
            { id: 'B601', status: 'occupied', selected: false },
            { id: 'B701', status: 'occupied', selected: false },
            { id: 'B801', status: 'occupied', selected: false },
            { id: 'B901', status: 'occupied', selected: false },
            { id: 'B1001', status: 'occupied', selected: false },
            { id: 'B1101', status: 'occupied', selected: false },
            { id: 'B1201', status: 'occupied', selected: false },
          ],
          [
              { id: 'B102', status: 'occupied', selected: false },
              { id: 'B202', status: 'occupied', selected: false },
              { id: 'B302', status: 'occupied', selected: false },
              { id: 'B402', status: 'occupied', selected: false },
              { id: 'B502', status: 'occupied', selected: false },
              { id: 'B602', status: 'occupied', selected: false },
              { id: 'B702', status: 'occupied', selected: false },
              { id: 'B802', status: 'occupied', selected: false },
              { id: 'B902', status: 'occupied', selected: false },
              { id: 'B1002', status: 'occupied', selected: false },
              { id: 'B1102', status: 'occupied', selected: false },
              { id: 'B1202', status: 'occupied', selected: false },
            ],
      ]
      selectedSeats: string[] = [];
    ngOnInit() { }
}