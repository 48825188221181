import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'route-maintenance-page',
  template: `
<div class="card flex flex-col justify-center items-start md:mt-20 xl:mt-5">
      <h1 class="sub-title-smaller !font-semibold text-tertiary/90 ml-5">Mantenimiento de rutas</h1>
      <div class="flex w-full items-center space-x-6">
        <input type="text" class="py-1.5 2xl:py-2.5 px-3 border border-gray-400 shadow-[#023877]/10 w-full md:w-4/6 mx-6 placeholder:text-sm" placeholder="Buscar rutas" [(ngModel)]="textOfSearchBar">
        <div class="relative w-auto">
          <div class="absolute inset-x-0 top-0 flex ml-5 items-center">
            <label for="status" class="bg-secondary text-primary text-xs">Estado</label>
          </div>
          <select name="status" id="status" class="select text-sm !border-gray-400" [(ngModel)]="statusFilter">
            <option value="">Todos</option>
            <option value="Inactivo">Inactivo</option>
            <option value="Activo">Activo</option>
          </select>
        </div>
        <button class="button primary">Agregar ruta</button>
      </div>

      <div class="flex w-full overflow-x-auto">
        <table class="w-full text-center items-center mt-6 2xl:mt-8">
          <thead>
            <th class="tableTH">ID</th>
            <th class="tableTH">Origen</th>
            <th class="tableTH">Destinos</th>
            <th class="tableTH">Estado</th>
            <th class="tableTH">Acciones</th>
          </thead>
          <tbody>
            <tr class="w-full text-sm text-gray-700 border border-tertiary/10" *ngFor="let route of routes | filter : textOfSearchBar | paginate: { itemsPerPage: itemsPerPage, currentPage: p }">
              <td class="tableTD">{{ route.id }}</td>
              <td class="tableTD">{{ route.origin.name }}</td>
              <td class="tableTD">{{ getDestinationNames(route.destinations) }}</td>
              <td class="tableTD">{{ route.status.name }}</td>
              <td class="tableTD space-x-3">
                <fa-icon icon="edit" class=" hover:bg-warning/20  bg-tertiary/5 text-warning p-2 rounded cursor-pointer"></fa-icon>
                <fa-icon icon="trash" class="hover:bg-danger/20 bg-tertiary/5 text-danger p-2 rounded cursor-pointer"></fa-icon>
              </td>
            </tr>
            <tr *ngIf="(routes | filter : textOfSearchBar) < 1" class="text-center">
              <td colspan="8" class="text-xl text-gray-700 py-4 2xl:py-6">
                No se encontró ningún registro
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex w-full justify-end">
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
      </div>
    </div>
  `,
  styles: [`
    .container {
      max-width: 1200px;
    }
  `]
})
export class RouteMaintenancePage implements OnInit {
    textOfSearchBar: string = '';
    statusFilter: string = '';
    p: number = 1;
    itemsPerPage: number = 5;
  
    branches = [
      { id: 1, name: 'Sucursal Santo Domingo', address: 'Avenida Principal 123', latitude: 18.4861, longitude: -69.9312, province: { id: 1, name: 'Santo Domingo' }, status: { id: 1, name: 'Activo' } },
      { id: 2, name: 'Sucursal Santiago', address: 'Calle Secundaria 456', latitude: 19.4500, longitude: -70.7000, province: { id: 2, name: 'Santiago' }, status: { id: 2, name: 'Inactivo' } },
      { id: 3, name: 'Sucursal La Vega', address: 'Boulevard 789', latitude: 19.2221, longitude: -70.5169, province: { id: 3, name: 'La Vega' }, status: { id: 1, name: 'Activo' } },
      { id: 4, name: 'Sucursal Puerto Plata', address: 'Carretera 101', latitude: 19.7930, longitude: -70.6884, province: { id: 4, name: 'Puerto Plata' }, status: { id: 2, name: 'Inactivo' } },
      { id: 5, name: 'Sucursal San Francisco', address: 'Avenida Libertad 202', latitude: 19.3000, longitude: -70.2500, province: { id: 5, name: 'San Francisco de Macorís' }, status: { id: 1, name: 'Activo' } },
      { id: 6, name: 'Sucursal La Romana', address: 'Calle del Sol 303', latitude: 18.4333, longitude: -68.9667, province: { id: 6, name: 'La Romana' }, status: { id: 2, name: 'Inactivo' } },
      { id: 7, name: 'Sucursal Higüey', address: 'Avenida Central 404', latitude: 18.6153, longitude: -68.7070, province: { id: 7, name: 'Higüey' }, status: { id: 1, name: 'Activo' } },
      { id: 8, name: 'Sucursal Barahona', address: 'Calle Principal 505', latitude: 18.2098, longitude: -71.1008, province: { id: 8, name: 'Barahona' }, status: { id: 2, name: 'Inactivo' } },
      { id: 9, name: 'Sucursal San Pedro', address: 'Carretera Este 606', latitude: 18.4500, longitude: -69.3000, province: { id: 9, name: 'San Pedro de Macorís' }, status: { id: 1, name: 'Activo' } },
      { id: 10, name: 'Sucursal Bonao', address: 'Avenida Norte 707', latitude: 18.9333, longitude: -70.4000, province: { id: 10, name: 'Bonao' }, status: { id: 2, name: 'Inactivo' } }
    ];
  
    routes = [
      { id: 1, origin: this.branches[0], destinations: [{ destination: this.branches[1] }, { destination: this.branches[2] }], status: { id: 1, name: 'Activo' } },
      { id: 2, origin: this.branches[1], destinations: [{ destination: this.branches[2] }, { destination: this.branches[3] }], status: { id: 2, name: 'Inactivo' } },
      { id: 3, origin: this.branches[2], destinations: [{ destination: this.branches[3] }, { destination: this.branches[4] }], status: { id: 1, name: 'Activo' } },
      { id: 4, origin: this.branches[3], destinations: [{ destination: this.branches[4] }, { destination: this.branches[5] }], status: { id: 2, name: 'Inactivo' } },
      { id: 5, origin: this.branches[4], destinations: [{ destination: this.branches[5] }, { destination: this.branches[6] }], status: { id: 1, name: 'Activo' } },
      { id: 6, origin: this.branches[5], destinations: [{ destination: this.branches[6] }, { destination: this.branches[7] }], status: { id: 2, name: 'Inactivo' } },
      { id: 7, origin: this.branches[6], destinations: [{ destination: this.branches[7] }, { destination: this.branches[8] }], status: { id: 1, name: 'Activo' } },
      { id: 8, origin: this.branches[7], destinations: [{ destination: this.branches[8] }, { destination: this.branches[9] }], status: { id: 2, name: 'Inactivo' } },
      { id: 9, origin: this.branches[8], destinations: [{ destination: this.branches[9] }, { destination: this.branches[0] }], status: { id: 1, name: 'Activo' } },
      { id: 10, origin: this.branches[9], destinations: [{ destination: this.branches[0] }, { destination: this.branches[1] }], status: { id: 2, name: 'Inactivo' } }
    ];
  
    constructor() { }
  
    ngOnInit(): void { }
  
    getFilteredRoutes() {
      return this.routes.filter(route => {
        return (
          route.origin.name.toLowerCase().includes(this.textOfSearchBar.toLowerCase()) ||
          route.destinations.some(d => d.destination.name.toLowerCase().includes(this.textOfSearchBar.toLowerCase())) &&
          (this.statusFilter === '' || route.status.name === this.statusFilter)
        );
      });
    }
  
    getDestinationNames(destinations: { destination: { name: string } }[]): string {
      return destinations.map(d => d.destination.name).join(', ');
    }
  
}
