import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RegisterData, User } from 'src/models/models';
import { DataService } from '../services/Data.services';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'signup-page',
  template: `
<div class="flex flex-col items-center">
    <form (ngSubmit)="signUp()" [formGroup]="signupForm" class="grid grid-cols-12 w-[90vw] xl:w-[55vw] 2xl:w-[40vw] pt-[2rem] overflow-y-auto overflow-x-hidden">
            <div class="col-span-12 place-items-center md:col-span-6 m-2.5 md:m-4">
                <label for="name" class="label">Nombre</label>
                <input formControlName="name" class="input" type="text" id="name" name="name" placeholder="Ingrese su nombre">
                <span *ngIf="signupForm.get('name')?.hasError('required') && signupForm.get('name')?.touched" class="text-red-500 text-xs italic">El campo es requerido</span>
            </div>
            <div class="col-span-12 place-items-center md:col-span-6 m-2.5 md:m-4">
                <label for="last_name" class="label">Apellido</label>
                <input formControlName="last_name" class="input" type="text" id="last_name" name="last_name" placeholder="Ingrese su nombre apellido">
                <span *ngIf="signupForm.get('last_name')?.hasError('required') && signupForm.get('last_name')?.touched" class="text-red-500 text-xs italic">El campo es requerido</span>
            </div>
            <div class="col-span-12 md:col-span-6 m-2.5 md:m-4">
                <label for="email" class="label">Correo electrónico</label>
                <input formControlName="email" class="input" type="email" id="email" name="email" placeholder="Ingrese su correo electrónico">
                <span *ngIf="signupForm.get('email')?.hasError('required') && signupForm.get('email')?.touched" class="text-red-500 text-xs italic">El campo es requerido</span>
            </div>
            <div class="col-span-12 md:col-span-6 m-2.5 md:m-4">
      <label for="password" class="label">Contraseña</label>
      <div class="relative">
        <input [type]="passwordFieldType" formControlName="password" class="input" name="password" id="password" placeholder="Ingrese su contraseña">
        <span class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer top-2 text-sm text-tertiary/70" (click)="togglePasswordVisibility()">
          <i [class]="passwordFieldType === 'password' ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
        </span>
      </div>
      <span *ngIf="signupForm.get('password')?.hasError('required') && signupForm.get('password')?.touched" class="text-red-500 text-xs italic">El campo es requerido</span>
      <span *ngIf="signupForm.get('password')?.hasError('minlength') && signupForm.get('password')?.touched" class="text-red-500 text-xs italic">La contraseña debe tener mínimo 8 caracteres</span>
    </div>
    <div class="col-span-12 md:col-span-6 m-2.5 md:m-4">
      <label for="confirmPassword" class="label">Confirmar Contraseña:</label>
      <div class="relative">
        <input [type]="confirmPasswordFieldType" id="confirmPassword" formControlName="confirmPassword" class="input" placeholder="Confirma tu contraseña">
        <span class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer top-2 text-sm text-tertiary/70" (click)="toggleConfirmPasswordVisibility()">
          <i [class]="confirmPasswordFieldType === 'password' ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
        </span>
      </div>
      <span *ngIf="signupForm.errors?.['passwordMismatch'] && signupForm.get('confirmPassword')?.touched" class="text-red-500 text-xs italic">Las contraseñas no coinciden.</span>
    </div>
            <div class="flex flex-col col-span-12 items-center w-full ml-3 justify-center">
                <!-- <div class="flex w-full items-center m-2 md:m-4">
                  <input type="checkbox" formControlName="terms" id="terms" name="terms" value="terms" class="m-3 cursor-pointer p-2 w-4 h-4 rounded-sm z-20">
                  <label for="terms" class="text-gray-500 font-semibold text-xs xl:text-sm">De acuerdo con los Términos y Condiciones </label>
                </div> -->
                  <span *ngIf="signupForm.get('terms')?.hasError('required') && signupForm.get('terms')?.touched" class="text-red-500 text-xs italic">El campo es requerido</span>
              <button [disabled]="working || !signupForm.valid" [ngClass]="!signupForm.valid ? '!bg-primary/50' : 'shine-effect'" type="submit" class="button primary m-2 md:m-4 mb-20" type="submit">
                 <fa-icon *ngIf="working" icon="spinner" [pulse]="true"></fa-icon>
                 {{!working ? ' Regístrate' : 'Registrando...'}}
                 </button>
            
            </div> 
    </form>
  `,
  styles: []
})
export class SignUpPage {
  signupForm!: FormGroup;
  working: boolean = false;
  passwordFieldType: string = 'password';
  confirmPasswordFieldType: string = 'password';
  
  constructor(
    private fb: FormBuilder,
     private data: DataService,
     private router: Router,
     private toastr: ToastrService,

     ){
      this.createForm();
  }
  createForm() {
    this.signupForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required]]
    }, { validator: this.passwordMatchValidator });
  }
  togglePasswordVisibility(): void {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }
  toggleConfirmPasswordVisibility(): void {
    this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password';
  }
  

 passwordMatchValidator(fg: FormGroup) {
    return fg.get('password')!.value === fg.get('confirmPassword')!.value ? null : { 'passwordMismatch': true };
  }

  signUp() {
    if (this.signupForm.valid) {
      this.working = true

      const userData: RegisterData = {
        email: this.signupForm.value.email,
        firstName: this.signupForm.value.name,
        lastName: this.signupForm.value.last_name,
        password: this.signupForm.value.password,
        confirmPassword: this.signupForm.value.confirmPassword,
        companyId: null
      };
      this.data.registerUser(userData).subscribe({
        next: (data) => {
          this.toastr.success('Usuario registrado con éxito', 'Registro');
          this.router.navigate(['/login']);
          this.working = false

        },
        error: (error) => {
          const message = error.error?.errors ? Object.values(error.error.errors)[0] : 'Error desconocido durante el registro';
          this.toastr.error(message as string, 'Registro Fallido');
          console.error('Error al registrar:', error);
          this.working = false

        }
      });
    } else {
      this.signupForm.markAllAsTouched();
    }
  }
}