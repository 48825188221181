import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AgmCoreModule } from '@agm/core';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageNotFound } from './404/pageNotFound';
import { AppRoutingModule } from './app-routing.module';
import { ChangePasswordPage } from './auth/changePassword.page';
import { LoginPage } from './auth/login.page';
import { SignUpPage } from './auth/signup.page';
import { BranchOfficesPage } from './pages/FrontOffice/branchOffices.page';
import { ContainerComponent } from './pages/FrontOffice/container.component';
import { HelpPage } from './pages/FrontOffice/help.page';
import { MyTripsPage } from './pages/FrontOffice/myTrips.page';
import { myTicketsPage } from './pages/FrontOffice/myTickets.page';
import { paymentMethodPage } from './pages/FrontOffice/paymentMethod.page';
import { AboutUsPage } from './pages/FrontOffice/about-us.page';
import { AppComponent } from './app.component';
import { AuthContainerComponent } from './auth/AuthContainer.component';
import { ModalComponent } from 'src/components/modal.component';
import { ProvinceBranchOffice } from './pages/FrontOffice/ProvinceBranchOffice.page';
import { ProfilePage } from './pages/FrontOffice/user.page';
import { DataService } from './services/Data.services';
import { BusSeatComponent } from 'src/components/bus-seat.component';
import { MySeatComponent } from 'src/components/my-seat.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FormatTimePipe } from './pipes/formatTime.pipe';
import { HomePage } from './pages/FrontOffice/home.page';
import { TicketComponent } from 'src/components/ticket.component';
import { BackOfficeContainerComponent } from './pages/BackOffice/backoffice-container.component';
import { HeaderComponent } from './pages/BackOffice/shared/header/header.component';
import { FooterComponent } from './pages/BackOffice/shared/footer/footer.component';
import { SideNavComponent } from './pages/BackOffice/shared/side-nav/side-nav.component';
import { DashboardPage } from './pages/BackOffice/dashboard.page';
import { ObjToArrayPipe } from './pipes/objToArray.pipe';
import { CompanyPage} from './pages/BackOffice/company-maintenance/company-page.component';
import { BusPage } from './pages/BackOffice/bus-maintenance/bus-page.component';
import { BranchPage } from './pages/BackOffice/company-maintenance/branch-page.component';
import { BusTypePage } from './pages/BackOffice/bus-maintenance/bus-type.page';
import { BusAmenityPage } from './pages/BackOffice/bus-maintenance/bus-amenity-page';
import { RouteMaintenancePage } from './pages/BackOffice/routes-&-schedules/route-maintenance-page';

@NgModule({
  declarations: [

    // backoffice
    BackOfficeContainerComponent,
    HeaderComponent,
    FooterComponent,
    SideNavComponent,
    DashboardPage,
    BusPage,
    BusTypePage,
    CompanyPage,
    BranchPage,
    BusAmenityPage,
    RouteMaintenancePage,


    // Components
    AppComponent,
    ContainerComponent,
    AuthContainerComponent,
    ModalComponent,
    BusSeatComponent,
    MySeatComponent,
    TicketComponent,

    // Auth
    LoginPage,
    SignUpPage,
    ChangePasswordPage,

    // Pages
    HomePage,
    MyTripsPage,
    myTicketsPage,
    paymentMethodPage,
    BranchOfficesPage,
    ProvinceBranchOffice,
    AboutUsPage,
    HelpPage,
    ProfilePage,

    //404
    PageNotFound,
     //pipes
     FormatTimePipe,
     ObjToArrayPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    Ng2SearchPipeModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDpvULYUJ1C2B04fnaNfq16YTRD1RfNe2E'
    })
  ],
  providers: [DataService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far)
  }
 }
