import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFound } from './404/pageNotFound';
import { AuthContainerComponent } from './auth/AuthContainer.component';
import { ChangePasswordPage } from './auth/changePassword.page';
import { LoginPage } from './auth/login.page';
import { SignUpPage } from './auth/signup.page';
import { ContainerComponent } from './pages/FrontOffice/container.component';
import { BranchOfficesPage } from './pages/FrontOffice/branchOffices.page';
import { HelpPage } from './pages/FrontOffice/help.page';
import { MyTripsPage } from './pages/FrontOffice/myTrips.page';
import { myTicketsPage } from './pages/FrontOffice/myTickets.page';
import { paymentMethodPage } from './pages/FrontOffice/paymentMethod.page';
import { ProvinceBranchOffice } from './pages/FrontOffice/ProvinceBranchOffice.page';
import { AboutUsPage } from './pages/FrontOffice/about-us.page';
import { ProfilePage } from './pages/FrontOffice/user.page';
import { HomePage } from './pages/FrontOffice/home.page';
import { BackOfficeContainerComponent } from './pages/BackOffice/backoffice-container.component';
import { DashboardPage } from './pages/BackOffice/dashboard.page';
import { CompanyPage } from './pages/BackOffice/company-maintenance/company-page.component';
import { BusPage } from './pages/BackOffice/bus-maintenance/bus-page.component';
import { BranchPage } from './pages/BackOffice/company-maintenance/branch-page.component';
import { BusTypePage } from './pages/BackOffice/bus-maintenance/bus-type.page';
import { BusAmenityPage } from './pages/BackOffice/bus-maintenance/bus-amenity-page';
import { RouteMaintenancePage } from './pages/BackOffice/routes-&-schedules/route-maintenance-page';
import { PermissionGuard } from './guards/permission.guard';

const routes: Routes = [
  // Rutas del FrontOffice
  {path: '', component: AuthContainerComponent, children: [
    {path: 'login', component: LoginPage},
    {path: 'signUp', component: SignUpPage},
    {path: 'forgotPassword', component: ChangePasswordPage},
    {path: '', redirectTo: '/login', pathMatch: 'full'}
  ]},

  {path: '', component: ContainerComponent, children: [
    {path: 'home', component: HomePage, canActivate: [PermissionGuard]},
    {path: 'myTrips', component: MyTripsPage},
    {path: 'myTickets', component: myTicketsPage},
    {path: 'paymentMethod', component: paymentMethodPage},
    {path: 'branchOffices', component: BranchOfficesPage},
    {path: 'branchOffices/province/:id/:province/:latitude/:longitude', component: ProvinceBranchOffice},
    {path: 'rules', component: AboutUsPage},
    {path: 'help', component: HelpPage},
    {path: 'profile', component: ProfilePage},
    {path: '', redirectTo: '/home', pathMatch: 'full'}
  ]},

  {
    path: 'backoffice',
    component: BackOfficeContainerComponent,
    canActivate: [PermissionGuard],
    children: [
      {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
      {path: 'dashboard', component: DashboardPage},
      {path: 'buses/maintenance', component: BusPage},
      {path: 'buses-type/maintenance', component: BusTypePage},
      {path: 'buses-amenity/maintenance', component: BusAmenityPage},
      {path: 'routes/maintenance', component: RouteMaintenancePage},
      {path: 'company', component: CompanyPage},
      {path: 'branches', component: BranchPage},



      // más subrutas para backoffice
    ]
  },
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: '**', component: PageNotFound}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
