import { Component } from '@angular/core';
declare var particlesJS: any;
@Component({
  selector: 'about-us-page',
  template: `
  
  <div class=" min-h-screen xl:min-h-[95vh]">
    <!-- Título -->
    <section class="relative flex flex-col items-center justify-center h-screen  border-b bg-primary text-white text-center px-6 mb-8">
    <img src="../../assets/Movilissa3.png" class="absolute xl:hidden max-h-[2.5rem] mb-5 right-3 top-[1.5rem]">
    <div id="particles-js"  class="absolute !top-0 !left-0 w-screen h-full !z-0"></div>
        <div class="flex flex-col items-center justify-center xl:w-1/2 z-20">
            <h1 class="title mb-4 md:mb-12">
            ¿Qué es Movilissa?
            </h1>
            <span class="xs:text-base sm:text-lg md:text-2xl xl:text-xl  xl:mb-12 font-medium text-justify">Movilissa es una plataforma de búsqueda de viajes que ayuda a los usuarios a encontrar las mejores ofertas en boletos de autobús en toda la República Dominicana. Con una rápida búsqueda en la página web de Movilissa puedes comparar horarios y precios de autobús de diversas compañías de viaje. Todo desde un solo lugar, de manera fácil y sencilla. Además, no pagarás cargos adicionales.
            </span>
        </div>
    </section>
  <div class=" mx-auto px-4 sm:px-6 lg:px-4 xl:w-[70vw]">
      <div class="py-6 space-y-14 mb-8">
        <div class="flex flex-col justify-center items-center mb-8">
          <h1 class="text-center text-4xl font-bold text-primary mb-6">Nuestros socios confían en nosotros</h1>
          <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
            <!-- Dinámicamente incluir los logos de los socios, utilizando ngFor en Angular -->
            <div class="w-32 max-h-32 mx-auto p-5 bg-white shadow-lg rounded-lg" *ngFor="let partner of partners">
              <img [src]="partner.logo" [alt]="partner.name" class="h-20 max-h-24 mx-auto object-contain">
            </div>
          </div>
        </div>
        <h2 class="text-center text-3xl font-bold text-primary mb-6">¿Por qué utilizar Movilissa?</h2>
        <div class="md:flex text-center mb-12 md:space-x-5 space-y-5 md:space-y-0 w-full">

          <div class="text-center md:w-1/4 w-full">
            <fa-icon icon="dollar" class="text-success font-bold text-3xl"></fa-icon>
            <h2 class="text-xl font-semibold text-gray-600 mb-4">Mejores precios</h2>
            <p class="text-gray-700 mb-6">Nos asociamos con las principales compañías de autobuses y trenes para ofrecerle las mejores ofertas.</p>
          </div>
          <div class="text-center md:w-1/4 w-full">
          <fa-icon icon="bus" class="text-success font-bold text-3xl"></fa-icon>
          <h2 class="text-xl font-semibold text-gray-600 mb-4">Mejores opciones de viaje</h2>
          <p class="text-gray-700 mb-6">Te ayudamos a encontrar y comparar los mejores autobuses y trenes en un solo lugar.</p>
          </div>
          <div class="text-center md:w-1/4 w-full">
          <fa-icon icon="ticket" class="text-success font-bold text-3xl"></fa-icon>
          <h2 class="text-xl font-semibold text-gray-600 mb-4">Reservación fácil y rápida</h2>
          <p class="text-gray-700 mb-6">Reserva viajes rápidamente con un proceso de pago sencillo y sin complicaciones, en línea y en nuestra app.</p>
          </div>
          <div class="text-center md:w-1/4 w-full">
          <fa-icon icon="headset" class="text-success font-bold text-3xl"></fa-icon>
            <h2 class="text-xl font-semibold text-gray-600 mb-4">Rápido soporte al cliente</h2>
            <p class="text-gray-700">No nos andamos con rodeos. Respondemos en cuestión de minutos para ayudarte.</p>
          </div>
        </div>
         
          <section class="flex flex-col justify-center items-center container mb-12 xl:w-[70vw]">
            <h2 class="text-center text-3xl font-bold text-primary mb-6">Cómo encontrar boletos más económicos de autobús en Movilissa:</h2>
            <ol class="list-decimal list-inside mb-6 text-lg text-gray-700 px-2 space-y-2">
              <li>Ingresa tu ciudad de origen, destino y fechas preferidas de viaje.</li>
              <li>Compara todos los horarios y precios disponibles para esa ruta.</li>
              <li>Selecciona el viaje que mejor se ajuste a tus necesidades y añade más personas a tu reserva si lo deseas.</li>
              <li>Ingresa tu información personal y de pago para reservar tus boletos. Recibirás los boletos en tu correo electrónico, listos para imprimir o mostrar desde tu celular.</li>
              <li>¡Prepara tus maletas!</li>
            </ol>
          </section>
      </div>
    </div>
  </div>
  `,
  styles: []
})
export class AboutUsPage {
  partners = [
    { logo: '../../assets/espinal-logo.png', name: 'Transporte Espinal' },
    { logo: '../../assets/caribe-tours-logo.png', name: 'Caribe tours' },
    { logo: '../../assets/metro-logo.jpg', name: 'Metro autobuses' },
    { logo: '../../assets/aptpra-logo.webp', name: 'aptpra' },
    { logo: '../../assets/expreso-bavaro-logo.png', name: 'expreso bávaro' },
    { logo: '../../assets/vegano-logo.jpe', name: 'expreso vegano' },
    { logo: '../../assets/expreso-linero.jpeg', name: 'expreso linero' },
    { logo: '../../assets/kmatos-logo.jpeg', name: 'kmatos' },
    // Añadir más socios según sea necesario
  ];
  ngOnInit(): void {
    this.loadScript();
  }
  
  loadScript() {
    const node = document.createElement('script');
    node.src = 'assets/particles.min.js';
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    node.onload = () => this.initParticlesJS();
    document.getElementsByTagName('head')[0].appendChild(node);
  }
  
  initParticlesJS() {
    particlesJS.load('particles-js', 'assets/particles.json', function() {
      console.log('callback - particles.js config loaded');
    });
  }
 }
