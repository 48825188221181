import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'container-component',
  template: `
    <div class="relative overflow-x-hidden min-h-screen">
    <header class="hidden xl:flex items-center justify-between w-full  fixed top-0 z-30 bg-secondary h-12">
      <div class="flex justify-center px-6"><a routerLink="/home"><img src="../../assets/Movilissa3-white.png" class="max-h-12"></a></div>
      <div class="flex justify-between">
        <ul class="flex">
          <li *ngFor="let item of menu" class="relative flex items-center justify-center w-32 h-12 text-primary font-semibold cursor-pointer transition-all duration-300 ease-out hover:transform hover:-translate-y-1" [routerLink]="item.link" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="bg-primary text-secondary">
            {{item.name}}
            <span class="absolute bottom-0 left-0 w-full h-0.5 bg-secondary transform scale-x-0 transition-transform duration-300 ease-out hover:scale-x-100"></span>
          </li>
        </ul>
        <div class="flex justify-center items-center h-12 mx-6 w-full">
          <div class="flex justify-center items-center text-sm rounded-full bg-primary h-9 w-9 text-white cursor-pointer">
          <div tabindex="-1" class="group relative">
          <fa-icon icon="user" class="text-sm"></fa-icon>
              <div
                class="hidden text-center group-focus-within:block absolute top-full z-10 space-y-2 w-auto h-auto right-1 border bg-white rounded-lg shadow-xl text-[11px] 2xl:text-sm"
              >	
              <button (click)="logout()" class="moreMenuItem">Cerrar sesión</button>
              </div>
          </div>
        </div>
        </div>
      </div>
    </header>
    <header class="xl:hidden flex relative ">
        <div (click)="burgerMenuOpen =  true" [ngClass]="isPrimaryColor ? 'text-primary' : 'text-secondary'" class="flex flex-col justify-center items-center w-10 h-10 absolute bottom-top z-20 top-[1.5rem] left-3 p-3 cursor-pointer hover:scale-105 duration-100">
          <i class="fa-solid fa-bars text-xl"></i>
        </div>
    </header>
    <div class="xl:hidden flex justify-between w-full h-full absolute transition {{ burgerMenuOpen? 'right-0 ease-in-out delay-150' : 'right-full ease-in-out delay-150' }} ease-linear">
      <!--Menu hamburguesa abierto-->
      <div class="bg-white w-5/6 md:w-3/6 h-screen z-40  xl:hidden flex flex-col items-center">
        <!--Menu hamburguesa usuario-->
        <div class="flex flex-col w-full h-[20vh]">
          <div (click)="burgerMenuOpen =  false" routerLink="/profile" class="flex justify-center items-center text-sm rounded-full bg-primary p-4 mx-4 mt-12 ml-8 w-16 h-16 text-white cursor-pointer">
              <i class="fa-solid fa-user text-2xl "></i>
          </div>
        <p class="text-xl m-8 text-primary font-semibold -translate-y-5">Nasser Issa</p>
        </div>
      <!--Menu hamburguesa listado-->
      <ul class="flex flex-col my-6 w-11/12 space-y-8">
        <li class="flex items-center justify-center w-full text-sm 2xl:text-lg text-primary font-semibold cursor-pointer hover:ease-out hover:scale-105 duration-150" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="" *ngFor="let item of menu" routerLink="{{item.link}}">
          <div (click)="burgerMenuOpen =  false" class="flex justify-between items-center w-11/12 mx-8 space-x-4">
            <i class="{{item.icon}} text-2xl w-1/6"></i>
            <p class="text-lg w-5/6">{{item.name}}</p> 
          </div>
        </li>
      </ul>
      <div class="flex w-full justify-center items-center my-6">
        <button routerLink="/login" class="button-small primary">Cerrar sesión</button>
      </div>
      </div>
      <div (click)="burgerMenuOpen =  false" class="w-1/6 md:w-3/6 bg-black/30 z-40 "></div>
    </div>
    <main class="xl:mt-12 flex flex-col flex-1">
      <router-outlet></router-outlet>
    </main>
    <footer class="flex w-full justify-between items-center border-t bottom-0 left-0 right-0 py-2.5 px-3 z-20 bg-secondary absolute">
      <p class="text-xs font-bold text-primary mx-6 ">Copyright © {{ currentYear }}</p>
      <div class="flex items-center space-x-2">
        <p class="text-xs font-bold"> Powered by <a href="https://algorissa.com/" target="_blank" class="cursor-pointer text-primary hover:underline">Algorissa</a> </p>
        <a href="https://algorissa.com/" target="_blank">
          <img src="../assets/algorissa.png" class="w-8 cursor-pointer" alt="algorissa logo">
        </a>
      </div>
    </footer>
  </div>
  `,
  styles: []
})
export class ContainerComponent {
  title = 'contenedor';
  burgerMenuOpen = false;
  currentYear: number;
  isLightBackground = true;
  isPrimaryColor = false;

constructor(private router: Router) {
  this.currentYear = new Date().getFullYear();
  this.router.events.pipe(
    filter((event: any): event is NavigationEnd => event instanceof NavigationEnd)
  ).subscribe(event => {
    this.isPrimaryColor = event.url === '/myTrips' || event.urlAfterRedirects === '/myTrips' || event.url === '/help' || event.urlAfterRedirects === '/help';
  });
}
logout() {
  localStorage.removeItem('isLoggedIn');
  this.router.navigate(['/login']);
}
toggleBackground(): void {
  this.isLightBackground = !this.isLightBackground;
}
  menu = [
    { name: 'Inicio', link: '/home', icon: 'fa-solid fa-house ' },
    { name: 'Mis viajes', link: '/myTrips', icon: 'fa-solid fa-bus ' },
    { name: 'Mis tickets', link: '/myTickets', icon: 'fa-solid fa-ticket' },
    // { name: 'Método de pago', link: '/paymentMethod', icon: 'fa-solid fa-wallet ' },
    // { name: 'Horarios', link: '/schedules', icon: 'fa-solid fa-clock ' },
    // { name: 'Sucursales', link: '/branchOffices', icon: 'fa-solid fa-bus-simple ' },
    { name: 'Nosotros', link: '/rules', icon: 'fa-solid fa-building ' },
    { name: 'Ayuda', link: '/help', icon: 'fa-solid fa-headset ' },
  ]
}
