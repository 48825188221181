import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'bus-seat-component',
  template: `
<div class="flex flex-col w-auto mt-4 border border-black h-auto">
  <!--STERING WHEEL-->
  <div class="flex justify-start items-start h-auto mb-1 w-full">
    <svg class="w-5 m-1 flex" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" xml:space="preserve">
      <g id="steering_x5F_wheel">
        <path style="fill:#030104;" d="M16,0C7.164,0,0,7.164,0,16s7.164,16,16,16s16-7.164,16-16S24.836,0,16,0z M16,4
          c5.207,0,9.605,3.354,11.266,8H4.734C6.395,7.354,10.793,4,16,4z M16,18c-1.105,0-2-0.895-2-2s0.895-2,2-2s2,0.895,2,2
          S17.105,18,16,18z M4,16c5.465,0,9.891,5.266,9.984,11.797C8.328,26.828,4,21.926,4,16z M18.016,27.797
          C18.109,21.266,22.535,16,28,16C28,21.926,23.672,26.828,18.016,27.797z"/>
      </g>
    </svg>
  </div>
  <!--BUS SEATS-->
  <div class="flex w-full justify-between">
    <div class="flex w-1/2 mr-4 flex-col">
      <div class="flex flex-wrap justify-center" *ngFor="let row of seatsConfigA">
        <div class="flex flex-col" *ngFor="let seat of row">
          <svg (click)="selectSeat(seat)" class="w-10 m-1 cursor-pointer hover:scale-125 duration-150 relative" viewBox="-0.98 0 20.001 20.001" xmlns="http://www.w3.org/2000/svg">
            <g id="seat-car" transform="translate(-2.983 -1.999)">
              <path id="secondary" [attr.fill]="seat.status === 'occupied' ? '#fda5a5' : seat.status === 'selected' ? '#7bf3ab' : '#D6D9DE'" d="M7.38,15a1,1,0,0,1,.9.55A2.61,2.61,0,0,0,10.62,17h2.94a2.61,2.61,0,0,0,2.34-1.45,1,1,0,0,1,.9-.55h1.62L19,8.68a1,1,0,0,0-.55-1L17.06,7l-.81-3.24a1,1,0,0,0-1-.76H8.72a1,1,0,0,0-1,.76L6.94,7l-1.39.69a1,1,0,0,0-.55,1L5.58,15Z"/>
              <path id="primary" [attr.fill]="seat.status === 'occupied' ? '#fda5a5' : seat.status === 'selected' ? '#7bf3ab' : '#D6D9DE'" d="M5.58,15,5,8.68a1,1,0,0,1,.55-1L6.94,7l.81-3.24a1,1,0,0,1,1-.76h6.56a1,1,0,0,1,1,.76L17.06,7l1.39.69a1,1,0,0,1,.55,1L18.42,15H16.8a1,1,0,0,0-.9.55A2.61,2.61,0,0,1,13.56,17H10.62a2.61,2.61,0,0,1-2.34-1.45,1,1,0,0,0-.9-.55Zm10.32.55h0A2.61,2.61,0,0,1,13.56,17H10.62a2.61,2.61,0,0,1-2.34-1.45h0a1,1,0,0,0-.9-.55H5a1,1,0,0,0-1,1.16l.53,3.17a2,2,0,0,0,2,1.67h11a2,2,0,0,0,2-1.67L20,16.16A1,1,0,0,0,19,15H16.8a1,1,0,0,0-.9.55Z" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <text x="57%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="6px" fill="black">{{ seat.id }}</text>
            </g>
          </svg>
        </div>
      </div>
    </div>
    <div class="flex w-1/2 ml-4 flex-col">
      <div class="flex flex-wrap justify-center" *ngFor="let row of seatsConfigB">
        <div class="flex flex-col" *ngFor="let seat of row">
          <svg (click)="selectSeat(seat)" class="w-10 m-1 cursor-pointer hover:scale-125 duration-150 relative" viewBox="-0.98 0 20.001 20.001" xmlns="http://www.w3.org/2000/svg">
            <g id="seat-car" transform="translate(-2.983 -1.999)">
              <path id="secondary" [attr.fill]="seat.status === 'occupied' ? '#fda5a5' : seat.status === 'selected' ? '#7bf3ab' : '#D6D9DE'" d="M7.38,15a1,1,0,0,1,.9.55A2.61,2.61,0,0,0,10.62,17h2.94a2.61,2.61,0,0,0,2.34-1.45,1,1,0,0,1,.9-.55h1.62L19,8.68a1,1,0,0,0-.55-1L17.06,7l-.81-3.24a1,1,0,0,0-1-.76H8.72a1,1,0,0,0-1,.76L6.94,7l-1.39.69a1,1,0,0,0-.55,1L5.58,15Z"/>
              <path id="primary" [attr.fill]="seat.status === 'occupied' ? '#fda5a5' : seat.status === 'selected' ? '#7bf3ab' : '#D6D9DE'" d="M5.58,15,5,8.68a1,1,0,0,1,.55-1L6.94,7l.81-3.24a1,1,0,0,1,1-.76h6.56a1,1,0,0,1,1,.76L17.06,7l1.39.69a1,1,0,0,1,.55,1L18.42,15H16.8a1,1,0,0,0-.9.55A2.61,2.61,0,0,1,13.56,17H10.62a2.61,2.61,0,0,1-2.34-1.45,1,1,0,0,0-.9-.55Zm10.32.55h0A2.61,2.61,0,0,1,13.56,17H10.62a2.61,2.61,0,0,1-2.34-1.45h0a1,1,0,0,0-.9-.55H5a1,1,0,0,0-1,1.16l.53,3.17a2,2,0,0,0,2,1.67h11a2,2,0,0,0,2-1.67L20,16.16A1,1,0,0,0,19,15H16.8a1,1,0,0,0-.9.55Z" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <text x="57%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="6px" fill="black">{{ seat.id }}</text>
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>
  `,
  styles: []
})
export class BusSeatComponent implements OnInit {
    @Output() quantityChange = new EventEmitter<number>(); // Define el EventEmitter

    constructor(private toastr: ToastrService) { }

    seatsConfigA: any[][] = [];
    seatsConfigB: any[][] = [];

    selectedSeats: any[] = [];

    ngOnInit() {
        this.generateSeats('tipo1'); // Puedes cambiar 'tipo1' por el tipo de autobús que desees probar
    }

    selectSeat(seat: any) {
        if (this.selectedSeats.length < 5 || seat.selected) { // Allow deselection if already selected
            if (seat.status === 'occupied') {
                this.toastr.error('No puede seleccionar un asiento ocupado', 'Error');
            } else {
                seat.selected = !seat.selected; // Toggle the selected property of the seat
                seat.status = seat.selected ? 'selected' : 'available'; // Update the status property based on the selected property
                if (seat.selected) {
                    this.selectedSeats.push(seat); // Add seat object to selectedSeats array
                } else {
                    this.selectedSeats = this.selectedSeats.filter(s => s.id !== seat.id); // Remove seat object from selectedSeats array
                }
                this.quantityChange.emit(this.selectedSeats.length); // Emit the new quantity of selected seats
            }
            console.log(this.selectedSeats);
        } else {
            this.toastr.error('Solo se pueden seleccionar hasta 5 asientos', 'Error'); // Show an error message if the user tries to select more than 5 seats
        }
    }

    generateSeats(busType: string) {
        switch (busType) {
            case 'tipo1':
                this.seatsConfigA = this.createSeats(1, 20); // Cambiar a 20 filas por columna
                this.seatsConfigB = this.createSeats(21, 40); // Cambiar a 20 filas por columna
                break;
            case 'tipo2':
                this.seatsConfigA = this.createSeats(1, 20);
                this.seatsConfigB = this.createSeats(21, 40);
                break;
            // Añadir más casos según sea necesario
            default:
                this.seatsConfigA = this.createSeats(1, 20);
                this.seatsConfigB = this.createSeats(21, 40);
        }
    }

    createSeats(start: number, end: number): any[][] {
        const seats: any[][] = [];
        for (let i = start; i <= end; i++) {
            const rowIndex = Math.floor((i - 1) % 10); // Esto organiza los asientos en columnas de 10 filas
            if (!seats[rowIndex]) {
                seats[rowIndex] = [];
            }
            seats[rowIndex].push({
                id: i,
                status: 'available',
                selected: false
            });
        }
        return seats;
    }
}
