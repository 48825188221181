import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '../services/Data.services';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard{
  constructor(
    private dataService: DataService, 
    private router: Router, 
    private toastr: ToastrService
  ) {}
  canActivate(): boolean {
    if (!this.dataService.isLoggedIn()) {
      this.router.navigate(['/login']);
      this.toastr.error('No estás logueado. Por favor, inicia sesión', 'Acceso Denegado');
      return false;
    }
    return true;
  }
  
}
