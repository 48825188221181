import { Component } from '@angular/core';
import { Day, Departure, Entry, Province, Rating, Ticket } from 'src/models/models';
import { DataService } from '../../services/Data.services';
import { ToastrService } from 'ngx-toastr';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'home-page',
    template: `
<!-- this creates a google map on the page with the given lat/lng from -->
<!-- the component as the initial center of the map: -->
     <agm-map class="flex flex-col flex-1 h-screen md:h-[95vh] relative overflow-y-hidden" [zoom]="zoom2" [latitude]="lat" [longitude]="lng">
       <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
     </agm-map>
     <!--TICKET COMPRADO, AUTOBUS POR PARTIR-->

     <div *ngIf="ticketBought" class="homeInfoContainer absolute">
        <div class="hidden md:flex flex-col">
            <h1 class=" text-sm font-semibold my-1 mt-3 md:mt-2 mb-1">Comprado el 28/07/2024 a las  9:54 AM</h1>
            <h1 class=" text-xl font-semibold my-1 mb-2 2xl:mb-3 text-primary">Nasser Emil Issa Tavares</h1>
            <hr class=" border-black">
    
            <div class="flex flex-col items-center my-1 ml-0 text-sm 2xl:text-lg">
                <div class="flex w-11/12 my-0.5">
                  <b class="text-primary w-1/3 text-start">Origen:</b>
                  <p class="font-light text-blackw-2/3 text-start">{{ticketData.origin}}</p>
                </div>
                <div class="flex w-11/12 my-0.5">
                  <b class="text-primary w-1/3 text-start">Destino:</b>
                  <p class="font-light text-blackw-2/3 text-start">{{ticketData.destiny}}</p>
                </div>
                <div class="flex w-11/12 my-0.5">
                  <b class="text-primary w-1/3 text-start">Salida:</b>
                  <p class="font-light text-blackw-2/3 text-start">{{ticketData.departureTime}}</p>
                </div>
                <div class="flex w-11/12 my-0.5">
                  <b class="text-primary w-1/3 text-start">Fecha:</b>
                  <p class="font-light text-blackw-2/3 text-start">{{ticketData.date}}</p>
                </div>
                <div class="flex w-11/12 my-0.5">
                  <b class="text-primary w-1/3 text-start">Autobús:</b>
                  <p class="font-light text-blackw-2/3 text-start">{{ticketData.bus}}</p>
                </div>
                <div class="flex w-11/12 my-0.5">
                  <b class="text-primary w-1/3 text-start">Asiento:</b>
                  <p class="font-light text-blackw-2/3 text-start">{{ticketData.seat}}</p>
                </div>
                <div class="flex w-11/12 my-0.5">
                  <b class="text-primary w-1/3 text-start">Pasajero:</b>
                  <p class="font-light text-blackw-2/3 text-start">{{ticketData.user}}</p>
                </div>
            </div>
    
            <div class="hidden md:flex  w-full justify-end text-center">
            <button class="smallButton">Ver asientos</button>
            </div>
            <hr class="border-black">
        <div class="flex flex-col my-2 ml-0 font-semibold text-center">
            <p>Ticket code</p>
            <div class="flex justify-center my-1"><img src="../../assets/qr-code.webp" alt="QR code" class=" w-44 md:w-24 2xl:w-36"></div>
        </div>
        </div>
        <div *ngIf="ticketBought" class="h-[17rem] md:h-auto text-white pb-2 p-5 md:py-2 bg-primary rounded-t-lg xl:rounded-xl ">
            <h1 class="text-2xl my-1 text-start w-full pt-1.5 sm:pt-3 font-bold">{{getGreeting()}}</h1>
            <div class="text-justify">
                <p class="text-lg">Su próximo autobús partirá en:</p>
                <div class="flex items-center justify-between my-2 relative">
                    <p class="text-4xl font-light text-green-500" [ngClass]="{'text-yellow-500': minutes < 10}" [ngClass]="{'text-red-500': minutes < 5}"> {{ minutes }}:{{ seconds.toString().padStart(2, '00') }}</p>
                    <button class="Button mt-2 md:hidden" (click)="viewTicketDetail = true">Ver ticket</button>
                </div>
            </div>
            <p class="xl:hidden italic m-1 mt-8 xl:mt-4 ">Prevea posibles retrasos debido al tráfico.</p>
        </div>
    </div>

        <!--TICKET SIN COMPRAR-->
        <div *ngIf="!ticketBought" class="h-[17rem] absolute md:h-auto p-5 text-white !bg-primary homeInfoContainer space-y-4 xl:space-y-0 !justify-start">
            <h1 class="text-2xl font-semibold text-start">{{getGreeting()}}</h1>
            <div class="flex flex-col 2xl:text-lg text-justify mb-2 ml-0">
                <p>No tienes ningún viaje pendiente.</p>
                <p>¿Desea realizar uno?</p>
                <div class="flex items-center justify-end space-x-5 md:my-4 xl:my-0">
                    <i class="fa-solid fa-arrow-right text-xl"></i>
                    <button class="Button" routerLink="/home">Comprar ticket</button>
                </div>
            </div>
        </div>

        <modal-component *ngIf="viewTicketDetail" (close)="viewTicketDetail = false">
        <div class="flex md:flex flex-col w-[90vw] text-center justify-center bg-white rounded-lg p-5 h-auto">
            <h1 class="sub-title-smaller text-center text-primary">Detalle del ticket</h1>
     <h1 class="text-sm font-semibold my-1 mt-3 md:mt-2 mb-1">Comprado el 12/06/2024 a las  9:54 AM</h1>
     <h1 class="text-xl font-semibold my-1 mb-2 2xl:mb-3 text-primary">Nasser Emil Issa Tavares</h1>
     <hr class="border-black">
     <div class="flex flex-col h-auto items-center md:my-1 ml-0 text-lg md:text-sm 2xl:text-lg">
         <div class="flex w-11/12 my-0.5">
           <b class="text-primary w-1/3 text-start">Origen:</b>
           <p class="font-light text-blackw-2/3 text-start">{{ticketData.origin}}</p>
         </div>
         <div class="flex w-11/12 my-0.5">
           <b class="text-primary w-1/3 text-start">Destino:</b>
           <p class="font-light text-blackw-2/3 text-start">{{ticketData.destiny}}</p>
         </div>
         <div class="flex w-11/12 my-0.5">
           <b class="text-primary w-1/3 text-start">Salida:</b>
           <p class="font-light text-blackw-2/3 text-start">{{ticketData.departureTime}}</p>
         </div>
         <div class="flex w-11/12 my-0.5">
           <b class="text-primary w-1/3 text-start">Fecha:</b>
           <p class="font-light text-blackw-2/3 text-start">{{ticketData.date}}</p>
         </div>
         <div class="flex w-11/12 my-0.5">
           <b class="text-primary w-1/3 text-start">Autobús:</b>
           <p class="font-light text-blackw-2/3 text-start">{{ticketData.bus}}</p>
         </div>
         <div class="flex w-11/12 my-0.5">
           <b class="text-primary w-1/3 text-start">Asiento:</b>
           <p class="font-light text-blackw-2/3 text-start">{{ticketData.seat}}</p>
         </div>
         <div class="flex w-11/12 my-0.5">
           <b class="text-primary w-1/3 text-start">Pasajero:</b>
           <p class="font-light text-blackw-2/3 text-start">{{ticketData.user}}</p>
         </div>
     </div>
     <div class="flex w-full justify-end text-center">
     <button class="rounded-full text-sm md:text-xs 2xl:text-sm mb-3 md:px-2 py-1.5 px-4 mx-2 2xl:px-4 font-semibold bg-primary text-white md:py-0.5 my-1 cursor-pointer hover:ease-out hover:scale-105 duration-150">Ver asientos</button>
     </div>
     <hr class="border-black">
     <div class="flex flex-col my-2 ml-0 font-semibold text-center">
         <p>Ticket code</p>
         <div class="flex justify-center my-1"><img src="../../assets/qr-code.webp" alt="QR code" class=" w-44 md:w-24 2xl:w-36"></div>
     </div>
 </div>
        </modal-component>
  `,
    styles: [``]
})
export class MyTripsPage {
    viewTicketDetail = false // false
    ticketBought = true; //false
    interval_id!: NodeJS.Timer;
    isPrimaryColor = false;

    constructor(private data: DataService, private toastr: ToastrService, private router: Router) {
    }

    getGreeting(): string {
        const now = new Date();
        const options: Intl.DateTimeFormatOptions = { timeZone: 'America/Santo_Domingo', hour: 'numeric', hour12: false };
        const formatter = new Intl.DateTimeFormat([], options);
        const hour = parseInt(formatter.format(now));
    
        if (hour < 12) {
            return '¡Buenos días!';
        } else if (hour < 18) {
            return '¡Buenas tardes!';
        } else {
            return '¡Buenas noches!';
        }
    }

    // data dummie
    ticketData: Ticket = {
      id: 1,
      origin: "Santo Domingo",
      destiny: "Puerto Plata",
      date: "2024-08-01",
      departureTime: "5:00 PM",
      bus: "No. 04",
      seat: "B-302",
      user: "Nasser Emil Issa Tavares",
      quantity: 1
  };
    lat = 18.478417428102947;
    lng = -69.9431656653404;
    zoom2 = 16;
    minutes = 14
    seconds = 59
    hours = 4;
    ngOnInit() {
        this.theBusIsAboutToLeave()
    }
    ngOnDestroy() {
        clearInterval(this.interval_id)
    }
    theBusIsAboutToLeave() {
        this.interval_id = setInterval(() => {
            this.seconds -= 1
            this.seconds === 0 && this.minutes !== 0 ? (this.minutes -= 1, this.seconds = 59) : null;
        }, 1000)
    }
}