import { Component } from '@angular/core';

@Component({
  selector: 'branch-page',
  template: `
   <div class="card flex flex-col justify-center items-start md:mt-20 xl:mt-5">
      <h1 class="sub-title-smaller !font-semibold text-tertiary/90 ml-5">Sucursales</h1>
    <div class="flex w-full items-center space-x-6">
      <input type="text" class="py-1.5 2xl:py-2.5 px-3 border border-gray-400 shadow-[#023877]/10 w-full md:w-4/6 mx-6 placeholder:text-sm" placeholder="Buscar sucursales" [(ngModel)]="textOfSearchBar">
      <div class="relative w-28">
          <div class="absolute inset-x-0 top-0 flex ml-5 items-center">
              <label for="status" class="bg-secondary text-primary text-xs">Estado </label>
          </div>
          <select formControlName="status" name="status" id="status" class="select text-sm !border-gray-400">
            <option value="">Todos</option>
            <option value="activo">Activo</option>
            <option value="inactivo">Inactivo</option>
          </select>
        </div>
          <button class="button primary">Agregar sucursal</button>
    </div>

 
    <div class="flex w-full overflow-x-auto">
      <table class="w-full text-center items-center mt-6 2xl:mt-8">
        <thead>
        <th class="tableTH">ID</th>
            <th class="tableTH">Nombre</th>
            <th class="tableTH">Dirección</th>
            <th class="tableTH">Latitud</th>
            <th class="tableTH">Longitud</th>
            <th class="tableTH">Provincia</th>
            <th class="tableTH">Estado</th>
            <th class="tableTH">Acciones</th>
        </thead>
        <tbody >
          <tr class="w-full text-sm text-gray-700 border border-tertiary/10" *ngFor="let branch of branches | filter : textOfSearchBar | paginate: { itemsPerPage: 5, currentPage: p }">
          <td class="tableTD">{{ branch.id }}</td>
              <td class="tableTD">{{branch.name}}</td>
              <td class="tableTD">{{branch.address}}</td>
              <td class="tableTD">{{branch.latitude}}</td>
              <td class="tableTD">{{branch.longitude}}</td>
              <td class="tableTD">{{branch.province.name}}</td>
              <td class="tableTD">{{branch.status.name}}</td>
            <td class="tableTD space-x-3">
              <fa-icon icon="edit" class=" hover:bg-warning/20  bg-tertiary/5 text-warning p-2 rounded cursor-pointer"></fa-icon>
              <fa-icon icon="trash" class="hover:bg-danger/20 bg-tertiary/5 text-danger p-2 rounded cursor-pointer"></fa-icon>
            </td>
          </tr>
          <tr *ngIf="(branches | filter : textOfSearchBar) < 1" class="text-center">
            <td colspan="8" class="text-xl text-gray-700 py-4 2xl:py-6">
              No se encontró ningún registro
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex w-full justify-end">
      <pagination-controls (pageChange)="p = $event"></pagination-controls>
    </div>
  </div>
  `,
  styles: [
  ]
})
export class BranchPage {
  textOfSearchBar: string = '';
  p: number = 1;
	itemsPerPage: number = 5;
  branches = [
    { id: 1, name: 'Sucursal Santo Domingo', address: 'Avenida Principal 123', latitude: 18.4861, longitude: -69.9312, province: { id: 1, name: 'Santo Domingo' }, status: { id: 1, name: 'Activo' } },
    { id: 2, name: 'Sucursal Santiago', address: 'Calle Secundaria 456', latitude: 19.4500, longitude: -70.7000, province: { id: 2, name: 'Santiago' }, status: { id: 2, name: 'Inactivo' } },
    { id: 3, name: 'Sucursal La Vega', address: 'Boulevard 789', latitude: 19.2221, longitude: -70.5169, province: { id: 3, name: 'La Vega' }, status: { id: 1, name: 'Activo' } },
    { id: 4, name: 'Sucursal Puerto Plata', address: 'Carretera 101', latitude: 19.7930, longitude: -70.6884, province: { id: 4, name: 'Puerto Plata' }, status: { id: 2, name: 'Inactivo' } },
    { id: 5, name: 'Sucursal San Francisco', address: 'Avenida Libertad 202', latitude: 19.3000, longitude: -70.2500, province: { id: 5, name: 'San Francisco de Macorís' }, status: { id: 1, name: 'Activo' } },
    { id: 6, name: 'Sucursal La Romana', address: 'Calle del Sol 303', latitude: 18.4333, longitude: -68.9667, province: { id: 6, name: 'La Romana' }, status: { id: 2, name: 'Inactivo' } },
    { id: 7, name: 'Sucursal Higüey', address: 'Avenida Central 404', latitude: 18.6153, longitude: -68.7070, province: { id: 7, name: 'Higüey' }, status: { id: 1, name: 'Activo' } },
    { id: 8, name: 'Sucursal Barahona', address: 'Calle Principal 505', latitude: 18.2098, longitude: -71.1008, province: { id: 8, name: 'Barahona' }, status: { id: 2, name: 'Inactivo' } },
    { id: 9, name: 'Sucursal San Pedro', address: 'Carretera Este 606', latitude: 18.4500, longitude: -69.3000, province: { id: 9, name: 'San Pedro de Macorís' }, status: { id: 1, name: 'Activo' } },
    { id: 10, name: 'Sucursal Bonao', address: 'Avenida Norte 707', latitude: 18.9333, longitude: -70.4000, province: { id: 10, name: 'Bonao' }, status: { id: 2, name: 'Inactivo' } }
  ];

}
