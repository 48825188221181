import { Component } from '@angular/core';

@Component({
  selector: 'auth-container',
  template: `
  <div class="flex flex-col max-w-[100vw] overflow-x-hidden bg-white">
    <div class="flex flex-col w-full h-[30vh] rounded-b-lg shadow-lg shadow-primary/10 border-b justify-between items-center">
        <div class="flex flex-col mt-8 flex-1 items-center justify-center">
        <img src="../../assets/Movilissa3-white.png" class="max-h-[12vh]">
                    <!-- <span class="text-xs md:text-base promo-text  font-semibold w-4/6 md:w-full text-center">¡El primer gestor de búsqueda de autobuses del pais!</span> -->
          <span class="text-xs md:text-base promo-text  font-semibold w-full text-center">¡Conectamos gente, unimos destinos!</span>
            </div>
       <ul class="flex w-full md:w-4/5 xl:w-1/3 justify-between">
        <li class="flex items-center justify-center p-2 w-full text-sm 2xl:text-lg text-primary font-semibold cursor-pointer transition-all duration-300 ease-out hover:transform xl:hover:-translate-y-1" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="text-secondary bg-primary shine-effect" routerLink="/login">Inicia sesión</li>
        <li class="flex items-center justify-center p-2 w-full text-sm 2xl:text-lg text-primary font-semibold cursor-pointer transition-all duration-300 ease-out hover:transform xl:hover:-translate-y-1" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="text-secondary bg-primary shine-effect" routerLink="/signUp">Regístrate</li>
       </ul>
    </div>
    <div class="flex flex-col flex-1 justify-start items-center">
        <router-outlet></router-outlet>
    </div>

  </div>
  
  `,
styles: [`
  @keyframes moveGradient {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }

  .promo-text {
    background: linear-gradient(to right, #C3203D, #204571, #FFFFFF, #fc5c7d, #6c757d);
    background-size: 500% 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    animation: moveGradient 10s ease infinite;
  }
`]

})
export class AuthContainerComponent {
  title = 'confirmacion de email';
}