<aside class="h-screen bg-tertiary text-secondary" [ngClass]="{'w-64': open, 'w-16': !open}" 
       (mouseenter)="toggleSidenav(true)" (mouseleave)="toggleSidenav(false);">
    <div class="overflow-y-auto h-screen py-4">
      <ul class="space-y-2">
        <li class="flex items-center justify-center w-full mb-[3rem] ">
          <img src="../../../assets/Movilissa3-white.png" alt="Logo" class="w-44 cursor-pointer bg-white p-1 rounded {{ !open ? 'hidden' : ''}}">
        </li>
        <ng-container *ngFor="let item of menuItems">
          <li *ngIf="!item.children; else hasChildren" >
            <div class="hover:bg-primary hover:text-secondary transition-all duration-150 ease-linear hover:transform"  [routerLink]="item.link" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="bg-primary text-secondary">
                <a [routerLink]="item.route" class="flex items-center p-2.5" [ngClass]="{'px-3': open, 'justify-center': !open}">
                    <i class="fas" [ngClass]="item.icon"></i>
                    <span *ngIf="open" class="ml-3">{{ item.label }}</span>
                </a>
            </div>
          </li>
          <ng-template #hasChildren>
            <li>
              <button type="button" class="flex items-center w-full p-2.5 transition-all duration-150 ease-linear hover:transform hover:bg-primary hover:text-secondary font-semibold" [ngClass]="{'px-3 justify-between': open, 'justify-center': !open}"
                      (click)="item.expanded = !item.expanded">
                <span>
                  <i class="fas" [ngClass]="item.icon"></i>
                  <span *ngIf="open" class="ml-3">{{ item.label }}</span>
                </span>
                <i class="fas" *ngIf="open" [ngClass]="item.expanded ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
              </button>
              <ul *ngIf="open && item.expanded">
                <li *ngFor="let child of item.children">
                  <a [routerLink]="child.route" class="flex items-center p-2 hover:bg-primary hover:text-secondary transition-all duration-150 ease-linear hover:transform hover:scale-105">
                    <span class="flex items-center space-x-2 py-0.5">
                        <p class="pl-4">{{ child.label }}</p>
                    </span >
                  </a>
                </li>
              </ul>
            </li>
          </ng-template>
        </ng-container>
      </ul>
    </div>
  </aside>
