import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/Data.services';
import { BusType } from 'src/models/models';

@Component({
  selector: 'bus-type-page',
  template: `
    <div class="card flex flex-col justify-center items-start md:mt-20 xl:mt-5">
      <h1 class="sub-title-smaller !font-semibold text-tertiary/90 ml-5">Mantenimiento de modelos de autobuses</h1>
      <div class="flex w-full items-center space-x-6">
        <input type="text" class="py-1.5 2xl:py-2.5 px-3 border border-gray-400 shadow-[#023877]/10 w-full md:w-4/6 ml-6 placeholder:text-sm" placeholder="Buscar modelos" [(ngModel)]="textOfSearchBar">
        <div class="relative w-44">
          <div class="absolute inset-x-0 top-0 flex items-center">
            <label for="status" class="bg-secondary text-primary text-xs">Estado</label>
          </div>
          <select class="select text-sm !border-gray-400" [(ngModel)]="statusFilter">
            <option value="">Todos</option>
            <option value="Inactivo">Inactivo</option>
            <option value="Activo">Activo</option>
          </select>
        </div>
        <button (click)="creating = true; busTypeForm.reset()" class="button primary shine-effect">Agregar modelo</button>
      </div>

      <div class="flex w-full overflow-x-auto">
        <table class="w-full text-center items-center mt-6 2xl:mt-8">
          <thead>
            <th class="tableTH">ID</th>
            <th class="tableTH">Marca</th>
            <th class="tableTH">Modelo</th>
            <th class="tableTH">Capacidad</th>
            <th class="tableTH">Estado</th>
            <th class="tableTH">Acciones</th>
          </thead>
          <tbody>
            <tr class="w-full text-sm text-gray-700 border border-tertiary/10" *ngFor="let bus of filteredModels() | filter : textOfSearchBar | paginate: { itemsPerPage: 7, currentPage: p }">
              <td class="tableTD">{{ bus.id }}</td>
              <td class="tableTD">{{ bus.brand.description }}</td>
              <td class="tableTD">{{ bus.model }}</td>
              <td class="tableTD">{{ bus.capacity }}</td>
              <td class="tableTD">{{ bus.status.description }}</td>
              <td class="tableTD space-x-3">
                <fa-icon (click)="edit(bus)"  icon="edit" class=" hover:bg-warning/20  bg-tertiary/5 text-warning p-2 rounded cursor-pointer"></fa-icon>
                <fa-icon (click)="inactivate(bus)" icon="trash" class="hover:bg-danger/20 bg-tertiary/5 text-danger p-2 rounded cursor-pointer"></fa-icon>
              </td>
            </tr>
            <tr *ngIf="filteredModels().length === 0" class="text-center">
              <td colspan="8" class="text-xl text-gray-700 py-4 2xl:py-6">
                No se encontró ningún registro
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex w-full justify-end">
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
      </div>
    </div>
    <modal-component *ngIf="creating || editing" (close)="creating = editing = false">
    <h1 class="sub-title-smaller text-primary !font-semibold text-center">{{editing ? 'Editar modelo de autobus ' + editing : 'Crear modelo de autobus'}}</h1>
    <form (ngSubmit)="save()" [formGroup]="busTypeForm" autocomplete="off">
      <div class="flex flex-col m-4">
          <label for="brand" class="label">Marca</label>
          <input formControlName="brand" class="input" type="text" name="brand" id="brand" placeholder="Ingrese la marca">
          <span *ngIf="busTypeForm.get('brand')?.hasError('required') && busTypeForm.get('brand')?.touched" class="text-red-500 text-xs italic">El campo es requerido</span>
      </div>
      <div class="flex flex-col m-4">
          <label for="model" class="label">Modelo</label>
          <input formControlName="model" class="input" type="text" name="model" id="model" placeholder="Ingrese la modelo">
          <span *ngIf="busTypeForm.get('model')?.hasError('required') && busTypeForm.get('model')?.touched" class="text-red-500 text-xs italic">El campo es requerido</span>
      </div>
      <div class="flex flex-col m-4">
          <label for="capacity" class="label">Capacidad</label>
          <input formControlName="capacity" class="input" type="number" name="capacity" id="capacity" placeholder="Ingrese la capacidad">
          <span *ngIf="busTypeForm.get('capacity')?.hasError('required') && busTypeForm.get('capacity')?.touched" class="text-red-500 text-xs italic">El campo es requerido</span>
      </div>
      <div class="flex flex-col m-4">
          <label for="status" class="label">Estado</label>
          <select formControlName="status" class="select text-sm !border-gray-400">
            <option [value]="">Todos</option>
            <option [value]="1">Activo</option>
            <option [value]="2">Inactivo</option>
          </select>
          <span *ngIf="busTypeForm.get('status')?.hasError('required') && busTypeForm.get('status')?.touched" class="text-red-500 text-xs italic">El campo es requerido</span>
      </div>
      <div class="flex items-center justify-center mt-4">
        <button [disabled]="working || !busTypeForm.valid" [ngClass]="!busTypeForm.valid ? '!bg-primary/50' : 'shine-effect'" class="button primary" type="submit">
            <fa-icon *ngIf="working" icon="spinner" [pulse]="true"></fa-icon>
            {{ !working ? 'Guardar' : 'Guardando...' }}
        </button>
      </div>
    </form>
  </modal-component>
  `,
  styles: [`
    .container {
      max-width: 1200px;
    }
  `]
})
export class BusTypePage implements OnInit {
	editing: number | false = false
  creating = false
  working: boolean = false;
  textOfSearchBar: string = '';
  busTypeForm!: FormGroup;
  
  statusFilter: string = '';
  p: number = 1;
  itemsPerPage: number = 5;

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private toastr: ToastrService
  ){
    this.busTypeForm = this.fb.group({
      brand: new FormControl('', [Validators.required]),
      model: new FormControl('', [Validators.required]),
      capacity: new FormControl('', [Validators.required]),
      status: new FormControl('', [Validators.required]),
    })
    
   }
   async save(){
      if (this.busTypeForm.invalid) {
          this.busTypeForm.markAllAsTouched();
          return;
      }
  
      this.working = true;
      const { origin, destiny, date, company } = this.busTypeForm.value;
   }
   edit(bus: BusType){
    console.log(bus)
    this.editing = bus.id
    this.busTypeForm.patchValue({
      brand: bus.brand.description,
      model: bus.model,
      capacity: bus.capacity,
      status: bus.status.id,
    });

   }
   async inactivate(data: BusType) {
		if (confirm(`¿Seguro que quieres anular el modelo "${data.model}" con ID ${data.id}?`)) {
			// await data.customers.delete(el.id)
			// this.items.refresh()
		}
	}






  ngOnInit(): void { }

  filteredModels() {
    return this.buses.filter(bus => {
      return (
        bus.model.toLowerCase().includes(this.textOfSearchBar.toLowerCase()) &&
        (this.statusFilter === '' || bus.status.description === this.statusFilter)
      );
    });
  }
  // data dummie 
   buses: BusType[] = [
    { 
      id: 1,
      capacity: 50, 
      brand: { id: 1, description: 'Brand A' }, 
      model: 'Model X', 
      status: { id: 1, description: 'Activo' }
    },
    { 
      id: 2,
      capacity: 40, 
      brand: { id: 2, description: 'Brand B' }, 
      model: 'Model Y', 
      status: { id: 1, description: 'Activo' }
    },
    { 
      id: 3,
      capacity: 60, 
      brand: { id: 3, description: 'Brand C' }, 
      model: 'Model Z', 
      status: { id: 1, description: 'Activo' }
    },
    { 
      id: 4,
      capacity: 55, 
      brand: { id: 4, description: 'Brand D' }, 
      model: 'Model A', 
      status: { id: 1, description: 'Activo' }
    },
    { 
      id: 5,
      capacity: 45, 
      brand: { id: 5, description: 'Brand E' }, 
      model: 'Model B', 
      status: { id: 1, description: 'Activo' }
    },
    { 
      id: 6,
      capacity: 50, 
      brand: { id: 6, description: 'Brand F' }, 
      model: 'Model C', 
      status: { id: 1, description: 'Activo' }
    },
    { 
      id: 7,
      capacity: 40, 
      brand: { id: 7, description: 'Brand G' }, 
      model: 'Model D', 
      status: { id: 1, description: 'Activo' }
    },
    { 
      id: 8,
      capacity: 60, 
      brand: { id: 8, description: 'Brand H' }, 
      model: 'Model E', 
      status: { id: 1, description: 'Activo' }
    },
    { 
      id: 9,
      capacity: 55, 
      brand: { id: 9, description: 'Brand I' }, 
      model: 'Model F', 
      status: { id: 1, description: 'Activo' }
    },
    { 
      id: 10,
      capacity: 45, 
      brand: { id: 10, description: 'Brand J' }, 
      model: 'Model G', 
      status: { id: 1, description: 'Activo' }
    }
  ];
  

}
