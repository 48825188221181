import { Component } from '@angular/core';

@Component({
  selector: 'pageNotFound',
  template: `
  <div class="py-10 px-28 flex flex-col h-screen items-center justify-center">

<h1 class="label text-3xl">¡Oops la página que buscabas no ha sido encontrada!</h1>
<img src="../../assets/404 Error Page not Found with people connecting a plug-rafiki.svg" alt="404 error" class="w-96 h-96">
<button class="button w-44" routerLink="/home">Volver</button>
</div>
  
  `,
  styles: []
})
export class PageNotFound {
  title = 'pagina no encontrada';
}