import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../services/Data.services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'changePassword-page',
  template: `
  <div class="flex flex-col items-center w-[100vw]">
    <form (ngSubmit)="sendEmail()" [formGroup]="forgotPasswordForm"class="my-6 2xl:mt-20 w-5/6 xl:w-1/4">
            <div class="flex flex-col m-4">
                <label for="user" class="label !text-tertiary italic font-light text-sm xl:text-base text-justify px-1">Ingresa un correo electrónico asociado con tu cuenta y te enviaremos un enlace para que puedas resetear tu contraseña.</label>
                <input formControlName="userEmail" class="input" type="text" name="user" id="user" placeholder="Ingrese su usuario">
                <span *ngIf="forgotPasswordForm.get('userEmail')?.hasError('required') && forgotPasswordForm.get('userEmail')?.touched" class="text-red-500 text-xs italic">El campo es requerido</span>
            </div>
            <div class="flex w-full justify-center items-center space-x-6 md:space-x-8">
              <button class="button danger" routerLink="/login">Volver</button>
              <button
              [disabled]="working || !forgotPasswordForm.valid"
              [ngClass]="!forgotPasswordForm.valid ? '!bg-primary/50' : 'shine-effect'"
              class="button primary" type="submit">
              <fa-icon *ngIf="working" icon="spinner" [pulse]="true"></fa-icon>
              {{!working ? ' Enviar' : 'Enviando...'}}
              </button>  
            </div>
</form>
    <modal-component *ngIf="modalIsOpen">
      <div class="flex flex-col items-center justify-center px-3 xl:px-10 py-4 space-y-5">
        <div class="flex w-full justify-end items-center cursor-pointer text-primary" (click)="modalIsOpen = false"><i class="fa-solid fa-xmark text-2xl"></i></div>
        <img src="../../assets/emailConfirmation.jpg" class="w-32" alt="email confirmation">
        <h1 class="font-bold text-2xl text-primary text-center">Confirmación de correo electrónico</h1>
        <p class="text-center text-sm ">Hemos enviado un correo a <span class="text-primary font-medium">{{this.forgotPasswordForm.value.userEmail}}</span> para <br> confirmar la validez de su dirección de correo electrónico.<br> Luego de recibir el correo, siga el enlace provisto para <br>completar su registro.</p>
        <div>
          <p class="text-sm text-center xl:text-sm xl:text-start">Si no ha recibido ningún correo <span class="font-bold text-primary ">reenvíelo nuevamente.</span></p>
          <div class="flex -wifull items-center justify-center m-4">
            <button
              [disabled]="working || !forgotPasswordForm.valid"
              [ngClass]="!forgotPasswordForm.valid ? '!bg-primary/50' : 'shine-effect'"
              (click)="sendEmail()"
              class="button-small primary">
              {{!working ? ' Reenviar' : 'Reenviando...'}}
              <i *ngIf="!working" class="fa-solid fa-paper-plane ml-2"></i>
              <fa-icon *ngIf="working" icon="spinner" [pulse]="true"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </modal-component>
  </div>
  `,
  styles: []
})
export class ChangePasswordPage {
  modalIsOpen: boolean = false;
  working: boolean = false
  forgotPasswordForm: FormGroup
  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private toastr: ToastrService,

  ) {
    this.forgotPasswordForm = this.fb.group({
      userEmail: new FormControl('', [Validators.required, Validators.email]),
    })
  }
  
  sendEmail(){
    if (!this.forgotPasswordForm.valid) return 

      this.working = true
      console.log('El correo del usuario: ', this.forgotPasswordForm.value.userEmail)
      this.data.sendEmail(this.forgotPasswordForm.value.userEmail).subscribe(
        (res) => {
          this.working = false
          this.modalIsOpen = true
          this.toastr.success(`El correo se ha enviado de manera exitosa`, 'Correo enviado');
        },
        (error) => {
          const message = error.error?.errors ? Object.values(error.error.errors)[0] : 'No se pudo enviar el correo';
          this.toastr.error(message as string, 'Envío Fallido');
          console.error('Error al registrar:', error);
          this.working = false
        }
      )}
}