import { Component } from '@angular/core';
import { Day, Departure, Entry, Province, Ticket, TicketAvailableList } from 'src/models/models';
import { DataService } from '../../services/Data.services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var particlesJS: any;
@Component({
  selector: 'myTickets-page',
  template: `
      <section class="relative flex flex-col xl:items-center bg-primary text-secondary h-[25vh] xl:h-[30vh] mb-8">
      <img src="../../assets/Movilissa3.png" class="absolute xl:hidden max-h-10 mb-5 right-3 top-[1.5rem]">
      <div id="particles-js"  class="absolute !top-0 !left-0 w-screen h-full !z-0"></div>
      <div class="flex flex-col items-center justify-center absolute inset-0">
        <h1 class="title">Mis tickets</h1>
      </div>
    </section>
  <div class="h-auto overflow-y-auto w-full mt-[2rem]">
            <div id="result" #resultSection class="grid grid-cols-12 flex-grow items-stretch border-b text-primary">
            <!-- publicidad -->
        <div class="h-auto  hidden xl:flex flex-col xl:col-span-2  items-center">
                <img src="../../../assets/verticalAdd1.png" alt="Publicidad" class="mt-8">
            </div>
            <!-- listado -->
            
            <div class="col-span-12  xl:col-span-8 mx-6 text-center">
            <h1 class="text-start sub-title">Filtrar</h1>
        <div class="grid grid-cols-12 w-full items-center justify-center pt-3 md:mb-[3rem] md:!space-x-6 !md:space-y-0 ">
          <input type="text" class="col-span-12 md:col-span-6 input !m-0" placeholder="Buscar tickets" [(ngModel)]="textOfSearchBar">
          <form [formGroup]="filter" class="!m-0 col-span-12 md:col-span-6">
            <select formControlName="filter" class="select text-sm mt-4 md:mt-0 ">
              <option value="" default selected>Filtrar por</option>
              <option *ngFor="let item of filterOption" [value]="item.value">{{item.name}}</option>
            </select>
          </form>
        </div>
                <h1 *ngIf="tickets_availables.length == 0" class="sub-title-smaller mt-7">¡No tienes tickets!</h1>
                <!-- Contenidos de resultados aquí -->
                <div  class="flex flex-col h-auto xl:mx-6 rounded-lg items-center">
                        <ticket-component *ngFor="let ticket of tickets_availables" [ticket]="ticket"></ticket-component>   
                        <div class="flex justify-center h-screen">
                            <img  *ngIf="tickets_availables.length == 0" class="h-1/2 w-auto max-h-1/2" src="../../assets/no-data.jpg">
                        </div>
                    </div>
            </div>
            <!-- publicidad -->
            <div class="h-auto  hidden xl:flex flex-col xl:col-span-2 space-y-24  items-center">
            <div *ngFor="let ad of advertisements">
        <a [href]="ad.link" target="_blank">
            <img [src]="ad.image" alt="Publicidad" class="w-44">
        </a>
    </div>
            </div>
          </div>
      </div>
  `,
  styles: []
})
export class myTicketsPage {
  filter: FormGroup;
  tickets_availables: TicketAvailableList[] = []
  constructor(private fb: FormBuilder){
    this.filter = this.fb.group({
      filter: ["", Validators.required]
  })
  }
  textOfSearchBar: string = '';
  ngOnInit(): void {
    this.loadScript();
  }
  
  loadScript() {
    const node = document.createElement('script');
    node.src = 'assets/particles.min.js';
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    node.onload = () => this.initParticlesJS();
    document.getElementsByTagName('head')[0].appendChild(node);
  }
  
  initParticlesJS() {
    particlesJS.load('particles-js', 'assets/particles.json', function() {
      console.log('callback - particles.js config loaded');
    });
  }
  filterOption = [
    { name: 'Menor precio', value: 1 },
    { name: 'Mayor precio', value: 2 },
    { name: 'Más temprano', value: 3 },
    { name: 'Más tarde', value: 4 },
    { name: 'Menor duración', value: 5 },
    { name: 'Mayor duración', value: 6 }
    ];
    advertisements = [
      {
      image: '../../../assets/temuAdd.png',
      link: 'https://example.com/target-page1'
      },
      {
      image: '../../../assets/temuAdd2.png',
      link: 'https://example.com/target-page2'
      },
      ];
}