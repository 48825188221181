import { Component } from '@angular/core';
import { EmailValidator, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../services/Data.services';
import { LoginData, LoginResponse } from 'src/models/models';

@Component({
  selector: 'login-page',
  template: `
  <div class="flex flex-col items-center w-[100vw]">
    <form (ngSubmit)="login()" [formGroup]="loginForm" class="grid grid-rows-2 w-[90vw] xl:w-[35vw] 2xl:w-[30vw] pt-[2rem]">
            <div class="flex flex-col m-4">
                <label for="user" class="label">Usuario</label>
                <input formControlName="userEmail" class="input" type="text" name="user" id="user" placeholder="Ingrese su usuario">
                <span *ngIf="loginForm.get('userEmail')?.hasError('required') && loginForm.get('userEmail')?.touched" class="text-red-500 text-xs italic">El campo es requerido</span>
            </div>
            <div class="flex flex-col m-4">
      <label for="password" class="label">Contraseña</label>
      <div class="relative">
        <input [type]="passwordFieldType" formControlName="password" class="input" name="password" id="password" placeholder="Ingrese su contraseña">
        <span class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer top-2 text-sm text-tertiary/70" (click)="togglePasswordVisibility()">
          <i [class]="passwordFieldType === 'password' ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
        </span>
      </div>
      <span *ngIf="loginForm.get('password')?.hasError('required') && loginForm.get('password')?.touched" class="text-red-500 text-xs italic">El campo es requerido</span>
    </div>
            <p class="ml-4 text-primary font-medium cursor-pointer hover:text-yellow-600" routerLink="/forgotPassword">Olvidé mi contraseña</p>
            <div class="flex items-center justify-center mt-4">
            <button [disabled]="working || !loginForm.valid" [ngClass]="!loginForm.valid ? '!bg-primary/50' : 'shine-effect'" class="button primary" type="submit">
								<fa-icon *ngIf="working" icon="spinner" [pulse]="true"></fa-icon>
								{{ !working ? 'Iniciar sesión' : 'Iniciando sesión...' }}
							</button>
            </div>
           
          </form>
  </div>
  `,
  styles: []
})
export class LoginPage {
  loginForm!: FormGroup;
  working: boolean = false;
  passwordFieldType: string = 'password';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private data: DataService

    ){
    this.loginForm = this.fb.group({
      userEmail: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    })
  }
  togglePasswordVisibility(): void {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }
  login(): void {
    if (this.loginForm.valid) {
      let user: LoginData = {
        email: this.loginForm.value.userEmail,
        password: this.loginForm.value.password
      }
      this.working = true
      this.data.signIn(user).subscribe({
        next: (response: LoginResponse) => {
          this.toastr.success(`Bienvenido ${response.userName}`, 'Login Exitoso');
          this.router.navigate(['/home']);
          this.working = false
        },
        error: (error) => {
          const message = error.error?.errors ? Object.values(error.error.errors)[0] : 'Usuario o contraseña inválidos';
          this.toastr.error(message as string, 'Error de Inicio de Sesión');
          console.error('Error al iniciar sesión:', error);
          this.working = false

        }
      });
    } else {
      this.loginForm.markAllAsTouched();
      this.toastr.error('Debe llenar los campos requeridos', 'Error de Inicio de Sesión');
    }
  }
}
