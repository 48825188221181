import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dashboard-page',
  template: `
  <div class="flex flex-col w-full h-full justify-center items-center content-center">
    <!-- <img src="../../../assets/movilissa-logo.JPG"> -->
    <p class="text-7xl  font-light py-5">Movilissa </p>
    <p class=" text-7xl font-light">BackOffice</p>
</div>
  `,
  styles: [``]
})
export class DashboardPage implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
