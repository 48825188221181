<div class="flex justify-between items-center absolute top-0 right-0 w-auto h-16 bg-secondary -z-1 {{ sidenavOpen? 'left-64' : 'left-16 transition-all ease-in duration-300' }}">
    <div class="flex justify-center content-center items-center">
        <!--LOGO-->
        <div class="flex justify-center cursor-pointer items-start -left-[60px] absolute {{ sidenavOpen? 'hidden' : 'transition ease-in-out delay-150 ' }}">
            <img src="../../../assets/Movilissa3-white.png" class="w-auto max-h-6 bg-white rounded relative" />
        </div>
        <!--END LOGO-->

    </div>

    <div>
        <div class="flex justify-end items-center space-x-5 w-full px-5 lg:text-sm">
            <!--IDIOMAS-->
            <div (click)="toogleLanguageMenu()">
                <span class="text-gray-500 hover:text-black cursor-pointer">Español (República Dominicana)</span>
                <!--DROPDOWN IDIOMAS-->
                <ul #AsDropdown *ngIf="isLanguageMenuOpened" class="absolute flex flex-col w-56 text-xs space-y-2 text-zinc-700 bg-white top-16 shadow-lg right-60 rounded">
                    <li>
                        <a routerLink="" class="flex items-center justify-start w-full px-5 py-2 rounded transition duration-75 group hover:bg-gray-100">
                            <i class="fa-brands fa-canadian-maple-leaf"></i>
                            <span class="ml-5 ">English</span>
                        </a>
                    </li>
                     <li>
                        <a routerLink="" class="flex items-center justify-start w-full px-5 py-2 rounded transition duration-75 group hover:bg-gray-100">
                            <i class="fa-brands fa-font-awesome"></i>
                            <span class="ml-5 ">Español (República Dominicana)</span>
                        </a>
                    </li>
                </ul>
                <!--END DROPDOWN IDIOMAS-->
            </div>
            <!--END IDIOMAS-->
    
            <!--USER-->
            <div class="justify" (click)="toggleUserMenu()">
            <div class="flex justify-start items-center">
                    <span class="mr-5 text-gray-500 hover:text-black cursor-pointer">Nasser Emil Issa Tavares</span>
                    
                    <div class="flex bg-white rounded-full w-10 h-10 justify-center items-center content-center m-0 cursor-pointer">
                        <i class="fa-solid fa-user"></i>
                    </div>
                </div>
            </div>
            <!--END USER-->
            <!--DROPDOWN USER-->
            <ul #AsDropdown *ngIf="isUserMenuOpened" class="absolute flex flex-col w-36 space-y-2 text-zinc-700 bg-white top-16 shadow-lg right-6 rounded text-xs">
                <li>
                    <a routerLink="" class="flex items-center justify-start w-full px-5 py-2 rounded transition duration-75 group hover:bg-gray-100">
                        <i class="fa-solid fa-user"></i>
                        <span class="ml-5">Profile</span>
                    </a>
                </li>
                <li>
                    <a routerLink="" class="flex items-center justify-start w-full px-5 py-2 rounded transition duration-75 group hover:bg-gray-100">
                        <i class="fa-solid fa-gears"></i>
                        <span class="ml-5">Settings</span>
                    </a>
                </li>
                <li>
                    <a routerLink="" type="button" class="flex items-center justify-start w-full px-5 py-2 rounded transition duration-75 group hover:bg-gray-100">
                        <i class="fa-solid fa-eye"></i>
                        <span class="ml-5">Audit Log</span>
                    </a>
                </li>
                <hr class="w-full">
                <li>
                    <a routerLink="" class="flex items-center justify-start w-full px-5 py-2 rounded transition duration-75 group hover:bg-gray-100">
                        <i class="fa-solid fa-right-to-bracket"></i>
                        <span class="ml-5">Logout</span>
                    </a>
                </li>
            </ul>
            <!--END DROPDOWN USER-->
        </div>
    </div>
</div>
