import { HttpClient, HttpClientModule, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TicketAvailabilityData,TicketAvailableList, User, LoginData, RegisterData, LoginResponse, Item } from 'src/models/models';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  myAppUrl!: string;
  myApiUrl!: string;
  private isAuthenticated = false;

    constructor(private http: HttpClient){
      this.myAppUrl = environment.endpoint;
      this.myApiUrl = '/api/User';
    }

    // Auth endpoints
    signIn(user: LoginData): Observable<LoginResponse> {
      return this.http.post<LoginResponse>(this.myAppUrl + '/api/auth/login', user).pipe(
        tap(response => localStorage.setItem('isLoggedIn', 'true')),
        catchError(error => {
          console.error('Login failed:', error);
          throw error;
        })
      );
    }
  
    logout() {
      // Asumir lógica para cerrar sesión
      localStorage.removeItem('isLoggedIn');
    }
  
    isLoggedIn(): boolean {
      return localStorage.getItem('isLoggedIn') === 'true';
    }

    registerUser(userData: RegisterData): Observable<any> {
      return this.http.post(this.myAppUrl + '/api/auth/register', userData);
    }
    sendEmail(email: string): Observable<any>{
      const body = { email: email };
      return this.http.post(this.myAppUrl + '/api/auth/forgot-password', body)
    }

    //gets endopoints:
    get_provinces(): Observable<Item[]>{
      return this.http.get<Item[]>(this.myAppUrl + '/api/provinces')
    }
    get_companies(): Observable<Item[]>{
      return this.http.get<Item[]>(this.myAppUrl + '/api/companies')
    }
    check_ticket_availability(data: TicketAvailabilityData): Observable<TicketAvailableList[]> {
      let date = new Date(data.date);
      let params = new HttpParams()
          .set('originId', data.originId)
          .set('destinyId', data.destinyId)
          .set('date', date.toISOString());
      if (data.companyId !== undefined) {
          params = params.set('companyId', data.companyId);
      }
  
      return this.http.get<TicketAvailableList[]>(`${this.myAppUrl}/api/search/tickets_availables`, { params });
  }
      saveUser(user: User): Observable<any>{
        return this.http.post(this.myAppUrl + this.myApiUrl, user)
      }
}