import { Component, ElementRef, OnInit, ViewChild, Renderer2, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styles: [`
  aside {
  transition: width 0.3s ease-in-out;
  width: 16rem; /* Ancho cuando está abierto */
    }

    aside.w-16 {
      width: 4rem; /* Ancho cuando está cerrado */
    }

    a, button {
      overflow: hidden; /* Oculta el texto cuando el sidenav está cerrado */
    }

  `]
})
export class SideNavComponent {
  @Input() open: boolean = true; 
  @Output() toggle = new EventEmitter<boolean>();

  toggleSidenav(state: boolean) {
    this.open = state;
    this.toggle.emit(this.open);
    this.menuItems.forEach(item => {
      if (item.children) {
        item.expanded = false
      }
    });
  }

  menuItems: any[] = [
    {
      label: 'Dashboard',
      icon: 'fa-tachometer-alt',
      route: '/backoffice/dashboard',
      description: 'Resumen y estadísticas generales'
    },
    {
      label: 'Gestión de Usuarios',
      icon: 'fa-users',
      children: [
        { label: 'Lista de Usuarios', route: '/users/list', icon: 'chevron-right' },
        { label: 'Agregar Nuevo Usuario', route: '/users/add' },
        { label: 'Roles y Permisos', route: '/users/roles' }
      ]
    },
    {
      label: 'Gestión de Buses',
      icon: 'fa-bus',
      children: [
        { label: 'Listado de autobuses', route: '/backoffice/buses/maintenance'},
        { label: 'Aminidades', route: '/backoffice/buses-amenity/maintenance' },
        { label: 'Tipos de autobuses', route: '/backoffice/buses-type/maintenance' },
        { label: 'Alquileres', route: '/buses/maintenance' }
      ]
    },
    {
      label: 'Rutas y Horarios',
      icon: 'fa-route',
      children: [
        { label: 'Mantenimiento de Rutas', route: '/backoffice/routes/maintenance' },
        { label: 'Mantenimiento de Horarios', route: '/backoffice/schedules/maintenance' },
        { label: 'Asignación de Buses a Rutas', route: '/backoffice/routes/assign' }
      ]
    },
    {
      label: 'Gestión de Compañía',
      icon: 'fa-building',
      children: [
        { label: 'Compañía', route: '/backoffice/company' },
        { label: 'Sucursales', route: '/backoffice/branches' }
      ]
     
    }
  ];
}
