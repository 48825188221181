import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: []
})
export class FooterComponent {
  @Input() sidenavOpen = true;
  @Output() sidenav = new EventEmitter()

  currentYear: number;

  constructor() {
    this.currentYear = new Date().getFullYear();
  }
}
