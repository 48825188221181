import { Component } from '@angular/core';

@Component({
  selector: 'help-page',
  template: `
    <div class="pt-10 px-5 md:px-20 2xl:px-28 text-center flex flex-col">
      <h1 class="label text-3xl">¿Necesita ayuda?</h1>
      <p class="py-4 font-bold text-lg">
        ¡No dudes en comunicarte con nosotros, estaremos encantados en ayudarte!
      </p>
      <div class="flex flex-col space-y-4">
        <p>
          <span class="font-bold label">Correo: </span><br>
          soporte@algorissa.com
        </p>
        <p>
          <span class="font-bold label">Teléfono: </span><br>
          +1 (849)-530-8311
        </p>
      </div>
      <div class="flex w-full justify-center items-center py-6">
        <img src="../../assets/Movilissa3-white.png" class="w-44" alt="Movilissa Logo">
      </div>
      <p class="label">
      <span class="text-xs md:text-base promo-text  font-semibold w-full text-center">¡Conectamos gente, unimos destinos!</span>
      </p>
      <p class="pt-4 font-bold text-lg">Un producto de</p>
      <div class="flex w-full justify-center items-center">
        <a href="https://algorissa.com/" target="_blank">
          <img src="../../../assets/algorissa.png" class="w-24" alt="Algorissa Logo">
        </a>

    </div>
  `,
styles: [`
  @keyframes moveGradient {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }

  .promo-text {
    background: linear-gradient(to right, #C3203D, #204571, #FFFFFF, #fc5c7d, #6c757d);
    background-size: 500% 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    animation: moveGradient 10s ease infinite;
  }
`]
})
export class HelpPage {
}