import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'backoffice-container-page',
  template: `
  <div id="" class="relative flex w-full z-10 bg-secondary">
    <app-side-nav [open]="sidenavOpen" (toggle)="handleToggle($event)"></app-side-nav>
    <app-header [sidenavOpen]="sidenavOpen" (sidenav)="sidenavOpen = !sidenavOpen"></app-header>
    <app-footer [sidenavOpen]="sidenavOpen"></app-footer>
    <div class="absolute overflow-y-scroll right-0 top-16 bottom-16 transition-all {{ sidenavOpen? 'left-64' : 'left-16' }}">
        <router-outlet></router-outlet>
    </div>
</div>
`
})
export class BackOfficeContainerComponent{
  sidenavOpen = false
  handleToggle(open: boolean) {
    this.sidenavOpen = open;
  }
}
