import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'branchOffices-page',
    template: `
<!-- this creates a google map on the page with the given lat/lng from -->
<!-- the component as the initial center of the map: -->
<agm-map class="h-[100vh] xl:h-[85vh] relative" [zoom]="zoom2" [latitude]="lat" [longitude]="lng">
  <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
</agm-map>
<div class="flex flex-col bottom-0 md:bottom-20 xl:bottom-14 2xl:bottom-20 sm:left-6 z-30 rounded-t-xl md:rounded-xl h-auto w-full  md:w-80 2xl:w-96 absolute bg-white text-center shadow-lg shadow-primary/40}">
    <div class="flex pt-4 w-full justify-between items-center relative">
        <i route routerLink="../../../../../" class="fa-solid fa-chevron-left w-auto cursor-pointer top-4 left-5 absolute text-start pl-2 text-2xl text-primary"></i>
        <p></p>
    </div>
    <div class="flex flex-col items-center space-y-7"> 
            <p class="flex text-xl 2xl:text-2xl text-center font-semibold text-primary">{{branchOffice.province | titlecase}}</p>
           <p>
           <span class="font-extrabold label text-xl">Correo: </span>
            <br>
            {{branchOffice.province.replace(' ', '') | lowercase}}@adorosoft.com
            </p>
            <p>
            <span class="font-extrabold label text-xl">Teléfono: </span>
            <br>
            809-805-8311
            </p>
            <div class=" flex w-full items-center justify-center text-white px-5  bg-primary rounded-t-xl md:rounded-xl ">
                <button class="rounded-full bg-white text-sm 2xl:text-lg py-2 px-4 2xl:px-4 font-semibold  text-primary cursor-pointer hover:ease-out hover:scale-105 duration-150 my-6">Ir a esta dirección</button>
            </div>
       </div>
        </div>
  `,
    styles: []
})
export class ProvinceBranchOffice {
    
    lat = 18.478417428102947;
    lng = -69.9431656653404;
    zoom2 = 17
    provinces = [
        { id: 1, province: 'AZUA', img: '../../assets/azua.jpeg' },
        { id: 2, province: 'BAHORUCO', img: '../../assets/BAHORUCO.jpeg' },
        { id: 3, province: 'BARAHONA', img: '../../assets/BARAHONA.jpeg' },
        { id: 4, province: 'DAJABON', img: '../../assets/DAJABON.jpeg' },
        { id: 5, province: 'DISTRITO NACIONAL', img: '../../assets/DISTRITO NACIONAL.jpeg' },
        { id: 6, province: 'DUARTE', img: '../../assets/DUARTE.jpeg' },
        { id: 7, province: 'EL SEYBO', img: '../../assets/EL SEYBO.png' },
        { id: 8, province: 'ELIAS PIÑA', img: '../../assets/eliaspina.jpeg' },
        { id: 9, province: 'ESPAILLAT', img: '../../assets/ESPAILLAT.png' },
        { id: 10, province: 'HATO MAYOR', img: '../../assets/HATO MAYOR.webp' },
        { id: 11, province: 'HERMANAS MIRABAL', img: '../../assets/HERMANAS MIRABA.jpeg' },
        { id: 12, province: 'INDEPENDENCIA', img: '../../assets/INDEPENDENCIA.jpeg' },
        { id: 13, province: 'LA ALTAGRACIA', img: '../../assets/LA ALTAGRACIA.jpeg' },
        { id: 14, province: 'LA ROMANA', img: '../../assets/LA ROMANA.jpg' },
        { id: 15, province: 'LA VEGA', img: '../../assets/LA VEGA.jpeg' },
        { id: 16, province: 'MARIA TRINIDAD SANCHEZ', img: '../../assets/MARIA TRINIDAD SANCHEZ.jpeg' },
        { id: 17, province: 'MONSEÑOR NOUEL', img: '../../assets/monsenornouel.jpeg' },
        { id: 18, province: 'MONTE PLATA', img: '../../assets/MONTE PLATA.jpeg' },
        { id: 19, province: 'MONTECRISTI', img: '../../assets/MONTECRISTI.jpeg' },
        { id: 20, province: 'PEDERNALES', img: '../../assets/PEDERNALES.png' },
        { id: 21, province: 'PERAVIA', img: '../../assets/PERAVIA.png' },
        { id: 22, province: 'PUERTO PLATA', img: '../../assets/PUERTO PLATA.jpeg' },
        { id: 23, province: 'SAMANA', img: '../../assets/SAMANA.jpeg' },
        { id: 24, province: 'SAN CRISTOBAL', img: '../../assets/SAN CRISTOBAL.webp' },
        { id: 25, province: 'SAN JOSE DE OCOA', img: '../../assets/SAN JOSE DE OCOA.jpeg' },
        { id: 26, province: 'SAN JUAN', img: '../../assets/SAN JUAN.jpeg' },
        { id: 27, province: 'SAN PEDRO DE MACORIS', img: '../../assets/SAN PEDRO DE MACORIS.jpeg' },
        { id: 28, province: 'SANCHEZ RAMIREZ', img: '../../assets/SANCHEZ RAMIREZ.jpeg' },
        { id: 29, province: 'SANTIAGO', img: '../../assets/SANTIAGO.jpeg' },
        { id: 30, province: 'SANTIAGO RODRIGUEZ', img: '../../assets/SANTIAGO RODRIGUEZ.jpeg' },
        { id: 31, province: 'SANTO DOMINGO', img: '../../assets/SANTO DOMINGO.jpeg' },
        { id: 32, province: 'VALVERDE', img: '../../assets/VALVERDE.jpeg' },
    ]
    branchOffice!: {id: number, province: string, latitude: string, longitude: string}
    
    constructor(private rutaActiva: ActivatedRoute)
    {

    }
    ngOnInit(){
        this.branchOffice = {
            id: this.rutaActiva.snapshot.params['id'],
            province: this.rutaActiva.snapshot.params['province'],
            latitude: this.rutaActiva.snapshot.params['latitude'],
            longitude: this.rutaActiva.snapshot.params['longitude']
        }
        this.rutaActiva.params.subscribe(
            (params: Params) => {
              this.branchOffice.id = params['id'];
              this.branchOffice.province = params['province'];
              this.lat = parseFloat(params['latitude']);
              this.lng = parseFloat(params['longitude']);
            }
          );
    }
}