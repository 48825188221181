import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bus-page',
  template: `
    <div class="card flex flex-col justify-center items-start md:mt-20 xl:mt-5">
      <h1 class="sub-title-smaller !font-semibold text-tertiary/90 ml-5">Mantenimiento de autobuses</h1>
      <div class="flex w-full items-center space-x-6">
        <input type="text" class="py-1.5 2xl:py-2.5 px-3 border border-gray-400 shadow-[#023877]/10 w-full md:w-4/6 mx-6 placeholder:text-sm" placeholder="Buscar autobuses" [(ngModel)]="textOfSearchBar">
        <div class="relative w-auto">
          <div class="absolute inset-x-0 top-0 flex ml-5 items-center">
            <label for="status" class="bg-secondary text-primary text-xs">Estado</label>
          </div>
          <select formControlName="status" name="status" id="status" class="select text-sm !border-gray-400" [(ngModel)]="statusFilter">
            <option value="">Todos</option>
            <option value="Disponible">Disponible</option>
            <option value="En servicio">En servicio</option>
            <option value="En servicio">En mantenimiento</option>
            <option value="En servicio">Reservado</option>
            <option value="En servicio">Fuera de servicio</option>

          </select>
        </div>
        <button class="button primary">Agregar autobús</button>
      </div>

      <div class="flex w-full overflow-x-auto">
        <table class="w-full text-center items-center mt-6 2xl:mt-8">
          <thead>
            <th class="tableTH">ID</th>
            <th class="tableTH">Número de Identificación</th>
            <th class="tableTH">Placa</th>
            <th class="tableTH">Modelo</th>
            <th class="tableTH">Marca</th>
            <th class="tableTH">Capacidad</th>
            <th class="tableTH">Estado</th>
            <th class="tableTH">Acciones</th>
          </thead>
          <tbody>
            <tr class="w-full text-sm text-gray-700 border border-tertiary/10" *ngFor="let bus of filteredBuses() | filter : textOfSearchBar | paginate: { itemsPerPage: 7, currentPage: p }">
              <td class="tableTD">{{ bus.id }}</td>
              <td class="tableTD">{{ bus.identificationNumber }}</td>
              <td class="tableTD">{{ bus.licensePlate }}</td>
              <td class="tableTD">{{ bus.model }}</td>
              <td class="tableTD">{{ bus.brand }}</td>
              <td class="tableTD">{{ bus.seatingCapacity }}</td>
              <td class="tableTD">{{ bus.status.name }}</td>
              <td class="tableTD space-x-3">
                <fa-icon icon="edit" class=" hover:bg-warning/20  bg-tertiary/5 text-warning p-2 rounded cursor-pointer"></fa-icon>
                <fa-icon icon="trash" class="hover:bg-danger/20 bg-tertiary/5 text-danger p-2 rounded cursor-pointer"></fa-icon>
              </td>
            </tr>
            <tr *ngIf="filteredBuses().length === 0" class="text-center">
              <td colspan="8" class="text-xl text-gray-700 py-4 2xl:py-6">
                No se encontró ningún registro
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex w-full justify-end">
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
      </div>
    </div>
  `,
  styles: [`
    .container {
      max-width: 1200px;
    }
  `]
})
export class BusPage implements OnInit {
  textOfSearchBar: string = '';
  statusFilter: string = '';
  p: number = 1;
  itemsPerPage: number = 5;

  buses = [
    { id: 1, identificationNumber: 'BUS-001', licensePlate: 'ABC123', model: 'Model X', brand: 'Brand A', seatingCapacity: 50, status: { name: 'Disponible' } },
    { id: 2, identificationNumber: 'BUS-002', licensePlate: 'DEF456', model: 'Model Y', brand: 'Brand B', seatingCapacity: 40, status: { name: 'En Servicio' } },
    { id: 3, identificationNumber: 'BUS-003', licensePlate: 'GHI789', model: 'Model Z', brand: 'Brand C', seatingCapacity: 60, status: { name: 'En Mantenimiento' } },
    { id: 4, identificationNumber: 'BUS-004', licensePlate: 'JKL012', model: 'Model A', brand: 'Brand D', seatingCapacity: 55, status: { name: 'Reservado' } },
    { id: 5, identificationNumber: 'BUS-005', licensePlate: 'MNO345', model: 'Model B', brand: 'Brand E', seatingCapacity: 45, status: { name: 'Fuera de Servicio' } },
    { id: 6, identificationNumber: 'BUS-006', licensePlate: 'PQR678', model: 'Model C', brand: 'Brand F', seatingCapacity: 50, status: { name: 'Disponible' } },
    { id: 7, identificationNumber: 'BUS-007', licensePlate: 'STU901', model: 'Model D', brand: 'Brand G', seatingCapacity: 40, status: { name: 'En Servicio' } },
    { id: 8, identificationNumber: 'BUS-008', licensePlate: 'VWX234', model: 'Model E', brand: 'Brand H', seatingCapacity: 60, status: { name: 'En Mantenimiento' } },
    { id: 9, identificationNumber: 'BUS-009', licensePlate: 'YZA567', model: 'Model F', brand: 'Brand I', seatingCapacity: 55, status: { name: 'Reservado' } },
    { id: 10, identificationNumber: 'BUS-010', licensePlate: 'BCD890', model: 'Model G', brand: 'Brand J', seatingCapacity: 45, status: { name: 'Fuera de Servicio' } }
  ];


  constructor() { }

  ngOnInit(): void { }

  filteredBuses() {
    return this.buses.filter(bus => {
      return (
        bus.identificationNumber.toLowerCase().includes(this.textOfSearchBar.toLowerCase()) &&
        (this.statusFilter === '' || bus.status.name === this.statusFilter)
      );
    });
  }
}
