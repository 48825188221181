import { Component } from '@angular/core';

@Component({
  selector: 'profile-page',
  template: `
  <div class="py-5 2xl:py-10 px-5 md:px-20 2xl:px-28 text-justify mt-5 xl:mt-0">

    <h1 class="label text-xl ml-12 md:ml-0 2xl:text-2xl">Perfil del cliente</h1>
    <div class="flex flex-col items-center w-full xl:w-4/5 2xl:w-2/3 shadow-xl rounded-xl">
        <div class="flex w-full bg-primary text-white rounded-t-xl px-5 py-2 mt-4" >Detalles del cliente </div>
        <div class="flex flex-col w-full items-center border rounded-b-xl px-5 " >
            <div class="flex w-full items-center my-1 2xl:my-3 border-b pb-3">
            <i class="fa-solid fa-user"></i>
            <p class="px-3 font-semibold text-primary text-sm xl::text-lg">Nombre:</p>
            <input type="text" class="w-full py-2 rounded-lg border pl-4" placeholder="Nasser Emil">
            </div>
            <div class="flex w-full items-center my-1 2xl:my-3 border-b pb-3">
            <i class="fa-regular fa-id-card"></i>
            <p class="px-3 font-semibold text-primary text-sm xl::text-lg">Apellido:</p>
            <input type="text" class="w-full py-2 rounded-lg border pl-4" placeholder="Issa Tavares">
            </div>
            <div class="flex w-full items-center my-1 2xl:my-3 border-b pb-3">
            <i class="fa-solid fa-envelope"></i>
            <p class="px-3 font-semibold text-primary text-sm xl::text-lg">Correo:</p>
            <input type="text" class="w-full py-2 rounded-lg border pl-4" placeholder="nasseremil25@gmail.com">
            </div>
            <div class="flex w-full items-center my-1 2xl:my-3 border-b pb-3">
            <i class="fa-solid fa-id-card"></i>
            <p class="px-3 font-semibold text-primary text-sm xl::text-lg">Cedula:</p>
            <input type="text" class="w-full py-2 rounded-lg border pl-4" placeholder="402-1442248-3">
            </div>
            <div class="flex w-full items-center my-1 2xl:my-3 border-b pb-3">
            <i class="fa-solid fa-phone"></i>
            <p class="px-3 font-semibold text-primary text-sm xl::text-lg">Numero:</p>
            <input type="tel" class="w-full py-2 rounded-lg border pl-4" placeholder="829-805-8311">
            </div>
            <div class="md:flex w-full items-center my-1 2xl:my-3 border-b pb-3 ">
              <div class="flex items-center mb-3 md:mb-0">
                <i class="fa-solid fa-lock"></i>
                <p class="px-3 font-semibold text-primary text-sm xl::text-lg">Cambiar contraseña:</p>
              </div>
            <input type="password" class="w-full py-2 rounded-lg border pl-4 md:mx-3 mb-3 md:mb-0" placeholder="*******">
            <input type="password" class="w-full py-2 rounded-lg border pl-4" placeholder="*******">
            </div>
            <button class="smallButton" style="padding: 8px 12px; !important">Guardar cambios
        </button>
        </div>

    </div>

  </div>
  `,
  styles: []
})
export class ProfilePage {
}