import { Component, ElementRef, ViewChild } from '@angular/core';
import { Province, Company, TicketAvailabilityData, TicketAvailableList, Item } from 'src/models/models';
import { DataService } from '../../services/Data.services';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

declare var particlesJS: any;

@Component({
  selector: 'home-page',
  template: `
    <!-- Título -->
     <section class="relative flex flex-col items-center justify-center h-[52vh] md:h-[50vh] xl:h-[52vh] 2xl:h-[58vh] border-b bg-primary text-white text-center px-6 mb-8">
     <div id="particles-js"  class="absolute !top-0 !left-0 w-screen h-full !z-0"></div>
     <img src="../../assets/Movilissa3.png" class="absolute xl:hidden max-h-[2.5rem] mb-5 right-3 top-[1.5rem]">
        <div class="flex flex-col items-center justify-between xl:w-1/2 z-10">
            <h1 class="title mb-4 xl:mb-12">
           Tu aventura en <br> <span class="text-info"> República Dominicana </span> <br> comienza aquí
            </h1>
            <span class="xs:text-base sm:text-lg md:text-2xl xl:text-xl 2xl:text-2xl mb-6 xl:mb-12 font-medium">¡Busca y compra tus boletos de autobús para cualquier destino del país de manera fácil y rápida!</span>
        </div>
    </section>
    <!-- Card con los filtros -->
    <section class="flex flex-col items-center relative md:px-0 text-primary mx-5">
        <form (ngSubmit)="check_ticket_availability()" [formGroup]="availability_form" class="grid grid-cols-10 justify-center xl:justify-between bg-secondary w-full xl:w-[90%] 2xl:w-[90%] xl:h-[70px] 2xl:h-[90px] py-2.4 rounded-xl px-6 md:px-12 xl:px-8 py-3 xl:py-0 absolute -top-[92px] md:-top-32 xl:-top-20 shadow-lg z-10 xl:space-x-5 xl:text-sm 2xl:text-base">
            <!-- Origen-->
            <div class="flex col-span-10 md:col-span-5 xl:col-span-2 space-x-3 items-center px-4 xl:pl-4 xl:pr-0 md:w-full mt-4 xl:mt-0">
                <fa-icon icon="location-dot"></fa-icon>
                <div class="relative w-full">
                    <div class="absolute inset-x-0 top-0 flex ml-5 items-center">
                        <label for="origin" class="bg-secondary text-primary px-2 text-xs">Origen  
                            <span class="text-danger">*</span>
                        </label>
                    </div>
                    <select formControlName="origin" name="origin" id="origin" class="select text-sm">
                        <option value="" selected default>seleccione origen</option>
                        <option *ngFor="let item of provinces" [value]="item.id">{{item.description}}</option>
                    </select>
                    <span *ngIf="availability_form.get('origin')?.hasError('required') && availability_form.get('origin')?.touched" class="text-danger text-sm">El campo es requerido</span>
                    </div>
            </div>
            <!-- Destino-->
            <div class="flex col-span-10 md:col-span-5 xl:col-span-2 space-x-3 items-center px-4 md:w-full mt-4 xl:mt-0">
                <fa-icon icon="map-location-dot"></fa-icon>
                <div class="relative w-full">
                    <div class="absolute inset-x-0 top-0 flex ml-5 items-center">
                        <label for="destiny" class="bg-secondary text-primary px-2 text-xs">Destino
                            <span class="text-danger">*</span>
                        </label>
                    </div>
                    <select formControlName="destiny" name="destiny" id="destiny" class="select text-sm">
                        <option value="" selected default>seleccione destino</option>
                        <option *ngFor="let item of provinces" [value]="item.id">{{item.description}}</option>
                    </select>
                    <span *ngIf="availability_form.get('destiny')?.hasError('required') && availability_form.get('destiny')?.touched" class="text-danger text-sm">El campo es requerido</span>
                </div>
            </div>
            <!--Fecha-->
            <div class="flex col-span-10 md:col-span-5 xl:col-span-2 space-x-3 items-center px-4 md:w-full mt-4 xl:mt-0 md:w:auto">
                <fa-icon icon="calendar-days"></fa-icon>
                <div class="relative w-full">
                    <div class="absolute inset-x-0 top-0 flex ml-5 items-center">
                        <label for="date" class="bg-secondary text-primary px-2 text-xs">Fecha   
                            <span class="text-danger">*</span>
                        </label>
                    </div>
                    <input formControlName="date" name="date" id="date" class="text-sm" name="date" id="date" type="date" placeholder="fecha">
                <span *ngIf="availability_form.get('date')?.hasError('required') && availability_form.get('date')?.touched" class="text-danger text-sm">El campo es requerido</span>
                </div>
            </div>
            <!--Compañía-->
            <div class="flex col-span-10 md:col-span-5 xl:col-span-2 space-x-3 items-center px-4 md:w-full mt-4 xl:mt-0 md:w:auto">
                <fa-icon icon="bus"></fa-icon>
                <div class="relative w-full">
                    <label for="company" class="absolute inset-x-0 top-0 flex ml-5 items-center">
                        <div class="bg-secondary text-primary px-2 text-xs">Compañía</div> 
                    </label>
                    <select formControlName="company" name="company" id="company" class="select text-sm">
                        <option value="" selected default>Todas las empresas</option>
                        <option *ngFor="let item of companies" [value]="item.id">{{item.description}}</option>
                    </select>
                </div>
            </div>
            <div class="flex col-span-10 md:col-span-10  xl:col-span-2 space-x-3 items-center justify-center w-full mt-2 xl:mt-0">
                <button
                type="submit"
                class="button primary w-11/12 md:w-auto !rounded-md my-4"
                [disabled]="working || !availability_form.valid"
                [ngClass]="!availability_form.valid ? '!bg-primary/50' : 'shine-effect'"
                >
                    <span class="text-md">
                    <fa-icon *ngIf="!working" icon="search"></fa-icon>
                    <fa-icon *ngIf="working" icon="spinner" [pulse]="true"></fa-icon>
                    {{!working ? 'Realizar búsqueda' : 'Realizando busqueda...'}}
                    </span>
                </button>
            </div>
        </form>
          <!-- Resultados de la búsqueda -->
          <div class="h-auto overflow-y-auto w-full pt-[22rem] md:pt-[176px] xl:pt-[2rem]">
            <div id="result" #resultSection class="grid grid-cols-12 flex-grow items-stretch border-b text-primary">
            <div class="h-auto  hidden xl:flex flex-col xl:col-span-2  items-center">
                <img src="../../../assets/verticalAdd1.png" alt="Publicidad" class="mt-8">
            </div>
            <!-- listado -->
            <div class="flex flex-col items-center text-center col-span-12 xl:col-span-8 mb-10">
            <img src="../../../assets/horizontalAdd0.png" alt="Publicidad" class="w-[80vw] xl:w-[39vw] 2xl:w-[35vw] mb-4">
            <h1 *ngIf="!searching" class="sub-title-smaller mb-4 2xl:mb-8">¡Realiza una búsqueda!</h1>
                    <h1 *ngIf="!tickets_availables && searching" class="sub-title-smaller mb-4 2xl:mb-8 md:pt-[3rem] xl:pt-[2rem]">{{!tickets_availables ? 'No se encontraron resultados' : 'Resultados de búsqueda:'  }}</h1>

                      
    <img  *ngIf="!tickets_availables && searching" class="h-1/2 max-h-1/2 xl:h-1/3 w-auto xl:max-h-1/3" src="../../assets/no-data.jpg">
    <img  *ngIf="!searching" class="h-44 w-auto max-h-1/2" src="../../assets/bus.jpg">

        <form  *ngIf="tickets_availables && tickets_availables.length > 0" [formGroup]="filter" class="flex flex-col w-full  xl:hidden rounded-xl">
          <h1 class="sub-title-smaller text-start !text-lg">Filtrar</h1>
    
          <select formControlName="filter" class="select text-sm xl:mb-20">
            <option value="" disabled selected>Filtrar por</option>
            <option *ngFor="let item of filterOption" [value]="item.value">{{item.name}}</option>
          </select>
     
      </form>       
            
    <!-- Loop de tickets con anuncios cada tres tickets -->
    <ng-container *ngFor="let ticket of tickets_availables; let i = index">
    <ticket-component [ticket]="ticket" (book_ticket)="handleTicketAcquired($event)"></ticket-component>
    <!-- Inyecta un anuncio cada tres tickets -->
    <ng-container *ngIf="(i + 1) % 3 === 0 && advertisements[Math.floor(i / 3)]">
        <div class="my-5 flex items-center justify-center">
            <a [href]="Horizontaladvertisements[Math.floor(i / 3)].link" target="_blank">
                <img [src]="Horizontaladvertisements[Math.floor(i / 3)].image" alt="Publicidad" class="max-w-full w-[80vw]  xl:w-[39vw] 2xl:w-[28vw] h-auto">
            </a>
        </div>
    </ng-container>
</ng-container>

</div>

                <!-- publicidad -->
                <div class="h-auto  hidden xl:flex flex-col xl:col-span-2  items-center mr-5">
                    <!-- Filtros para buscar boletos -->
                    <section class="flex flex-col items-center relative text-primary">
                        <form [formGroup]="filter" class="flex flex-col w-full rounded-xl  py-3 xl:py-0 -top-[92px] z-10 space-x-4">
          <h1 class="sub-title-smaller text-center !text-lg">Filtrar</h1>
        <div class="col-span-2 mb-5">
          <select formControlName="filter" class="select text-sm">
            <option value="" disabled selected>Filtrar por</option>
            <option *ngFor="let item of filterOption" [value]="item.value">{{item.name}}</option>
          </select>
        </div>
      </form>

      <!-- Sección de Contacto -->
      <div class="w-full xl:w-[90%] mt-8">
        <h1 class="sub-title-smaller text-center !text-lg">¿Quieres formar parte de Movilissa?</h1>
        <form class="flex flex-col items-center">
          <input type="text" placeholder="Su compañía" class="input text-sm mb-2">
          <input type="email" placeholder="Su correo electrónico" class="input text-sm mb-2">
          <textarea placeholder="Escriba tu mensaje aquí" class="input text-sm mb-4"></textarea>
          <button type="submit" class="button primary shine-effect">Enviar solicitud</button>
        </form>
      </div>
      <img src="../../../assets/temuAdd.png" alt="Publicidad" class="w-44 m-10 mb-20">

    </section>
                </div>
            </div>
        </div>
    </section>
    <modal-component *ngIf="selected_ticket" (close)="selected_ticket = undefined; quantity = 0">
        <h1 class="sub-title-smaller text-primary !font-semibold text-center">Comprar ticket</h1>
        <div class="xl:flex justify-start xl:p-6 h-full w-full md:w-[70vw] xl:w-auto">
            <!--SELECCIONAR ASIENTO-->
            <div class="flex flex-col h-full justify-between items-center mb-5">
                    <p class="text-2xl font-medium text-tertiary m-5">1. Seleccione sus asientos</p>
                <div class="grid grid-cols-3 mx-6">
                    <div class="flex flex-col items-center">
                    <svg class="w-5 m-1" viewBox="-0.98 0 20.001 20.001" xmlns="http://www.w3.org/2000/svg">
                        <g id="seat-car" transform="translate(-2.983 -1.999)">
                            <path id="secondary"  fill="#D6D9DE" d="M7.38,15a1,1,0,0,1,.9.55A2.61,2.61,0,0,0,10.62,17h2.94a2.61,2.61,0,0,0,2.34-1.45,1,1,0,0,1,.9-.55h1.62L19,8.68a1,1,0,0,0-.55-1L17.06,7l-.81-3.24a1,1,0,0,0-1-.76H8.72a1,1,0,0,0-1,.76L6.94,7l-1.39.69a1,1,0,0,0-.55,1L5.58,15Z"/>
                            <path id="primary" fill="#D6D9DE" d="M5.58,15,5,8.68a1,1,0,0,1,.55-1L6.94,7l.81-3.24a1,1,0,0,1,1-.76h6.56a1,1,0,0,1,1,.76L17.06,7l1.39.69a1,1,0,0,1,.55,1L18.42,15H16.8a1,1,0,0,0-.9.55A2.61,2.61,0,0,1,13.56,17H10.62a2.61,2.61,0,0,1-2.34-1.45,1,1,0,0,0-.9-.55Zm10.32.55h0A2.61,2.61,0,0,1,13.56,17H10.62a2.61,2.61,0,0,1-2.34-1.45h0a1,1,0,0,0-.9-.55H5a1,1,0,0,0-1,1.16l.53,3.17a2,2,0,0,0,2,1.67h11a2,2,0,0,0,2-1.67L20,16.16A1,1,0,0,0,19,15H16.8a1,1,0,0,0-.9.55Z" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        </g>
                    </svg>
                        <p>Disponible</p>
                    </div>
                    <div class="flex flex-col items-center">
                    <svg class="w-5 m-1" viewBox="-0.98 0 20.001 20.001" xmlns="http://www.w3.org/2000/svg">
                        <g id="seat-car" transform="translate(-2.983 -1.999)">
                            <path id="secondary"  fill="#fda5a5" d="M7.38,15a1,1,0,0,1,.9.55A2.61,2.61,0,0,0,10.62,17h2.94a2.61,2.61,0,0,0,2.34-1.45,1,1,0,0,1,.9-.55h1.62L19,8.68a1,1,0,0,0-.55-1L17.06,7l-.81-3.24a1,1,0,0,0-1-.76H8.72a1,1,0,0,0-1,.76L6.94,7l-1.39.69a1,1,0,0,0-.55,1L5.58,15Z"/>
                            <path id="primary" fill="#fda5a5" d="M5.58,15,5,8.68a1,1,0,0,1,.55-1L6.94,7l.81-3.24a1,1,0,0,1,1-.76h6.56a1,1,0,0,1,1,.76L17.06,7l1.39.69a1,1,0,0,1,.55,1L18.42,15H16.8a1,1,0,0,0-.9.55A2.61,2.61,0,0,1,13.56,17H10.62a2.61,2.61,0,0,1-2.34-1.45,1,1,0,0,0-.9-.55Zm10.32.55h0A2.61,2.61,0,0,1,13.56,17H10.62a2.61,2.61,0,0,1-2.34-1.45h0a1,1,0,0,0-.9-.55H5a1,1,0,0,0-1,1.16l.53,3.17a2,2,0,0,0,2,1.67h11a2,2,0,0,0,2-1.67L20,16.16A1,1,0,0,0,19,15H16.8a1,1,0,0,0-.9.55Z" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        </g>
                    </svg>
                        <p>Ocupado</p>
                    </div>
                    <div class="flex flex-col items-center">
                    <svg class="w-5 m-1" viewBox="-0.98 0 20.001 20.001" xmlns="http://www.w3.org/2000/svg">
                        <g id="seat-car" transform="translate(-2.983 -1.999)">
                            <path id="secondary"  fill="#88efab" d="M7.38,15a1,1,0,0,1,.9.55A2.61,2.61,0,0,0,10.62,17h2.94a2.61,2.61,0,0,0,2.34-1.45,1,1,0,0,1,.9-.55h1.62L19,8.68a1,1,0,0,0-.55-1L17.06,7l-.81-3.24a1,1,0,0,0-1-.76H8.72a1,1,0,0,0-1,.76L6.94,7l-1.39.69a1,1,0,0,0-.55,1L5.58,15Z"/>
                            <path id="primary" fill="#88efab" d="M5.58,15,5,8.68a1,1,0,0,1,.55-1L6.94,7l.81-3.24a1,1,0,0,1,1-.76h6.56a1,1,0,0,1,1,.76L17.06,7l1.39.69a1,1,0,0,1,.55,1L18.42,15H16.8a1,1,0,0,0-.9.55A2.61,2.61,0,0,1,13.56,17H10.62a2.61,2.61,0,0,1-2.34-1.45,1,1,0,0,0-.9-.55Zm10.32.55h0A2.61,2.61,0,0,1,13.56,17H10.62a2.61,2.61,0,0,1-2.34-1.45h0a1,1,0,0,0-.9-.55H5a1,1,0,0,0-1,1.16l.53,3.17a2,2,0,0,0,2,1.67h11a2,2,0,0,0,2-1.67L20,16.16A1,1,0,0,0,19,15H16.8a1,1,0,0,0-.9.55Z" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        </g>
                    </svg>
                        <p>Seleccionado</p>
                    </div>
                </div>
                <bus-seat-component (quantityChange)="updateQuantity($event)"></bus-seat-component>
            </div>
            <div class="flex flex-col space-y-4 bg-gray-100/50 border border-gray-100 xl:w-[25vw] px-4 py-1 rounded-lg">
                <p class="text-2xl font-medium text-tertiary text-center xl:m-5">2. Confirmar detalles del pago</p>
                <p class="text-info font-bold"><fa-icon icon="bus" class="mx-1"></fa-icon> Compañía: <span class="text-gray-400 font-medium">{{ selected_ticket.company.name }}</span></p>
                <p class="text-info font-bold"><fa-icon icon="arrow-up" class="mx-1"></fa-icon> Origen: <span class="text-gray-400 font-medium">{{ selected_ticket.origin.description }}</span></p>

                <p class="text-info font-bold"><fa-icon icon="map-location-dot" class="mx-1"></fa-icon> Destino: <span class="text-gray-400 font-medium">{{ selected_ticket.destiny.description }}</span></p>

                <p class="text-info font-bold"><fa-icon icon="calendar-days" class="mx-1"></fa-icon> Fecha: <span class="text-gray-400 font-medium">{{ selected_ticket.date | date }}</span></p>
                <p class="text-info font-bold"><fa-icon icon="calendar-days" class="mx-1"></fa-icon> Salida: <span class="text-gray-400 font-medium">{{ selected_ticket.departure_time }}</span></p>
                <p class="text-info font-bold"><fa-icon icon="user" class="mx-1"></fa-icon> Asientos: <span class="text-gray-400 font-medium">{{quantity}}</span></p>
                <p class="text-info font-bold"><fa-icon icon="user" class="mx-1"></fa-icon> Precio: <span class="text-gray-400 font-medium">$ {{ selected_ticket.price }}.00 RD</span></p>

                <div class="text-info font-bold">
                    <table class="w-full">
                        <tr>
                            <td class="text-left">Cantidad:</td>
                            <td class="text-right">{{ quantity }}</td>
                        </tr>
                        <tr>
                            <td class="text-left">Subtotal:</td>
                            <td class="text-right">$ {{ subtotal | number:'1.2-2'}} RD</td>
                        </tr>
                        <tr>
                            <td class="text-left">ITBIS (18%):</td>
                            <td class="text-right">$ {{ itbis | number:'1.2-2' }} RD</td>
                        </tr>
                 
                        <tr class="border-b border-gray-300">
                            <td class="text-left">Descuento:</td>
                            <td class="text-right">$ {{ discount | number:'1.2-2' }} RD</td>
                        </tr>
                        <tr>
                            <td class="text-left font-bold">Total:</td>
                            <td class="text-right font-bold">$ {{ total | number:'1.2-2' }} RD</td>
                        </tr>
                    </table>
                </div>

                <div class="flex flex-col w-full h-auto">
                    <div class="flex items-center mx-3 space-x-3 text-sm">
                        <i class="fa-solid fa-cart-shopping text-2xl"></i>
                        <p>MONTO <br> <span class="text-red-500 font-bold">{{total}} $RD</span></p>
                    </div>
                    <button type="submit" class="button primary shine-effect mt-3" style="padding: 8px 24px; !important;">Comprar</button>
                </div>
            </div>
        </div>
    </modal-component>
  `,
  styles: []
})
export class HomePage {
    Math = Math;  
    @ViewChild('particlesJs', { static: false }) particlesJs: ElementRef | undefined;
    @ViewChild('resultSection') resultSection: ElementRef<HTMLDivElement> | undefined;
    working: boolean = false
    searching: boolean | undefined = false
    selected_ticket: TicketAvailableList | undefined;
    provinces: Item[] = []
    companies: Item[] = []
    availability_form: FormGroup
    filter: FormGroup;
    quantity: number = 0; // Cantidad de asientos seleccionados
    tickets_availables: TicketAvailableList[] = []

    constructor(private fb: FormBuilder, private data: DataService, private toastr: ToastrService ){
        this.availability_form = this.fb.group({
            origin: ["", Validators.required],
            destiny: ["", Validators.required],
            date: ["", Validators.required],
            company: [""],
          })
        this.filter = this.fb.group({
            filter: ["", Validators.required]
        })

    }
  // Getter para calcular el subtotal
  get subtotal(): number {
    return this.selected_ticket ? this.selected_ticket.price * this.quantity : 0;
  }

  // Getter para calcular el ITBIS (18%)
  get itbis(): number {
    return this.subtotal * 0.18;
  }
   // Getter para calcular el descuento (5%)
   get discount(): number {
    return (this.subtotal + this.itbis)  * -0.05;
  }
  // Getter para calcular el total
  get total(): number {
    return (this.subtotal + this.itbis) + this.discount;
  }

    handleTicketAcquired(ticket: TicketAvailableList) {
        this.selected_ticket = ticket;
        console.log('Ticket acquired with ID:', ticket);
      }
      async check_ticket_availability() {
        if (this.availability_form.invalid) {
            this.availability_form.markAllAsTouched();
            return;
        }
    
        this.working = true;
        const { origin, destiny, date, company } = this.availability_form.value;
    
        let filter: TicketAvailabilityData = {
            originId: Number(origin),
            destinyId: Number(destiny),
            date: date,
            companyId: this.availability_form.value.company ? Number(this.availability_form.value.company) : undefined
        };
    
        this.data.check_ticket_availability(filter).subscribe(
            data => {
                this.searching = true;
                this.resultSection!.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                this.working = false;
                this.tickets_availables = data;
                console.log('listado de tickets disponibles: ', this.tickets_availables);
            },
            err => {
                console.error(err);
                this.working = false;
            }
        );
    }
    
  updateQuantity(quantity: number) {
    this.quantity = quantity;
}
  loadProvinces(): void {
    this.data.get_provinces().subscribe((data: Item[]) => {
      this.provinces = data;
    }, (err: any) => {
      console.error(err);
    });
  }

  loadCompanies(): void {
    this.data.get_companies().subscribe((data: Item[]) => {
      this.companies = data;
    }, (err: any) => {
      console.error(err);
    });
  }
  addTicket(){
    this.toastr.success('Ticket agregado al carrito de manera exitosa', 'Ticket agregado')
}
  ngOnInit(): void {
    this.loadScript();
    this.loadCompanies();
    this.loadProvinces();
    this.searching = false;
  }  
  loadScript() {
    const node = document.createElement('script');
    node.src = 'assets/particles.min.js';
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    node.onload = () => this.initParticlesJS();
    document.getElementsByTagName('head')[0].appendChild(node);
  }
  initParticlesJS() {
    particlesJS.load('particles-js', 'assets/particles.json', function() {
      console.log('callback - particles.js config loaded');
    });
  }

// data dummie

//     tickets_availables: TicketAvailableList[] = [
//         {
//           id: 1,
//           company: { id: 101, name: 'Caribe Tours', logo: '../../assets/caribetours-logo.png' },
//           origin: { id: 201, description: 'Santo Domingo' },
//           destiny: { id: 202, description: 'Santiago' },
//           date: new Date('2024-07-01'),
//           departure_time: '08:00 AM',
//           arribal_time: '11:00 AM',
//           estimated_duration: '3h',
//           price: 500,
//           seats_available: 20
//         },
//         {
//           id: 2,
//           company: { id: 102, name: 'Metro Autobuses', logo: '../../assets/metro-logo.jpg' },
//           origin: { id: 203, description: 'La Romana' },
//           destiny: { id: 204, description: 'Punta Cana' },
//           date: new Date('2024-07-02'),
//           departure_time: '09:00 AM',
//           arribal_time: '12:00 PM',
//           estimated_duration: '3h',
//           price: 800,
//           seats_available: 15
//         },
//         {
//           id: 3,
//           company: { id: 103, name: 'Trans. Espinal', logo: '../../assets/espinal-logo.png' },
//           origin: { id: 205, description: 'Puerto Plata' },
//           destiny: { id: 206, description: 'Samana' },
//           date: new Date('2024-07-03'),
//           departure_time: '07:30 AM',
//           arribal_time: '10:30 AM',
//           estimated_duration: '3h',
//           price: 550,
//           seats_available: 1
//         },
//         {
//           id: 4,
//           company: { id: 104, name: 'aptpra', logo: '../../assets/aptpra-logo.webp' },
//           origin: { id: 207, description: 'Santo Domingo' },
//           destiny: { id: 208, description: 'Bonao' },
//           date: new Date('2024-07-04'),
//           departure_time: '10:00 AM',
//           arribal_time: '11:30 AM',
//           estimated_duration: '1h 30m',
//           price: 350,
//           seats_available: 30
//         },
//         {
//           id: 5,
//           company: { id: 105, name: 'expreso bávaro', logo: '../../assets/expreso-bavaro-logo.png' },
//           origin: { id: 209, description: 'Punta Cana' },
//           destiny: { id: 210, description: 'Santo Domingo' },
//           date: new Date('2024-07-05'),
//           departure_time: '02:00 PM',
//           arribal_time: '05:00 PM',
//           estimated_duration: '3h',
//           price: 600,
//           seats_available: 20
//         },
//         {
//           id: 6,
//           company: { id: 106, name: 'expreso vegano', logo: '../../assets/vegano-logo.jpe' },
//           origin: { id: 211, description: 'La Vega' },
//           destiny: { id: 212, description: 'Santiago' },
//           date: new Date('2024-07-06'),
//           departure_time: '11:00 AM',
//           arribal_time: '12:15 PM',
//           estimated_duration: '1h 15m',
//           price: 300,
//           seats_available: 25
//         },
//         {
//           id: 7,
//           company: { id: 107, name: 'expreso linero', logo: '../../assets/expreso-linero.jpeg' },
//           origin: { id: 213, description: 'San Francisco de Macoris' },
//           destiny: { id: 214, description: 'Santo Domingo' },
//           date: new Date('2024-07-07'),
//           departure_time: '06:00 PM',
//           arribal_time: '08:30 PM',
//           estimated_duration: '2h 30m',
//           price: 400,
//           seats_available: 15
//         },
//         {
//           id: 8,
//           company: { id: 108, name: 'kmatos', logo: '../../assets/kmatos-logo.jpeg' },
//           origin: { id: 215, description: 'Santiago' },
//           destiny: { id: 216, description: 'Puerto Plata' },
//           date: new Date('2024-07-08'),
//           departure_time: '08:45 AM',
//           arribal_time: '10:30 AM',
//           estimated_duration: '1h 45m',
//           price: 320,
//           seats_available: 22
//         }
// ];
Horizontaladvertisements = [
{
image: '../../../assets/horizontalAdd1.png',
link: 'https://example.com/target-page1'
},
{
image: '../../../assets/horizontalAdd2.png',
link: 'https://example.com/target-page1'
},
{
image: '../../../assets/horizontalAdd3.png',
link: 'https://example.com/target-page1'
},
{
image: '../../../assets/horizontalAdd4.png',
link: 'https://example.com/target-page1'
},

]
advertisements = [
{
image: '../../../assets/temuAdd.png',
link: 'https://example.com/target-page1'
},
{
image: '../../../assets/temuAdd2.png',
link: 'https://example.com/target-page2'
},
{
image: '../../../assets/temuAdd3.png',
link: 'https://example.com/target-page3'
},
{
image: '../../../assets/temuAdd4.png',
link: 'https://example.com/target-page3'
}
];
filterOption = [
{ name: 'Menor precio', value: 1 },
{ name: 'Mayor precio', value: 2 },
{ name: 'Más temprano', value: 3 },
{ name: 'Más tarde', value: 4 },
{ name: 'Menor duración', value: 5 },
{ name: 'Mayor duración', value: 6 }
];
}