import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'modal-component',
  template: `
  <div
   class="fixed inset-0 backdrop-blur-sm backdrop-opacity-80 grid place-items-center bg-black bg-opacity-10 z-40"
   (mousedown)="down($event)"
	 (mouseup)="up($event)"
   >
    <div #content class="overflow-y-auto max-w-full max-h-full rounded-xl p-6 bg-white border border-gray-100 shadow-sm relative">
    <button
					*ngIf="close.observers.length"
					(click)="close.emit()"
					title="Cerrar"
					class="absolute top-1 right-1.5 hover:scale-110 transition-all hover:duration-75 text-2xl xl:text-xl 2xl:text-[23px] duration-300 hover:animate-pulse"
				>
					<fa-icon icon="xmark" class="text-primary hover:text-danger"></fa-icon>
				</button>
        <ng-content></ng-content>
    </div>
  </div>

  
  `,
  styles: []
})
export class ModalComponent {
  @Output() dismiss = new EventEmitter()
	@Output() close = new EventEmitter()
	@ViewChild('content') content!: ElementRef<HTMLDivElement>

	dismissing = false
	is_inside(target: any) {
		const content = this.content.nativeElement
		return content == target || content.contains(target)
	}
  down({ target }: MouseEvent) {
		this.dismissing = !this.is_inside(target)
	}
	up({ target }: MouseEvent) {
		if (!this.is_inside(target) && this.dismissing) this.dismiss.emit()
	}
  ngOnInit() {
    document.body.style.overflow = 'hidden';
  }

  ngOnDestroy() {
    document.body.style.overflow = 'auto';
  }
}