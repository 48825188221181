import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'company-page',
  template: `
      <div class="card flex flex-col justify-center items-start md:mt-20 xl:mt-5">
      <h1 class="sub-title-smaller !font-semibold text-tertiary/90 ml-5">Datos de la Compañía</h1>
    
    <div class="flex w-full overflow-x-auto">
      <table class="w-full text-center items-center mt-3">
          <th>Logo</th>
          <th>Empresa</th>
          <th>Numero</th>
          <th>Correo</th>
          <th>Cantidad de flotillas</th>
          <th>Estado</th>
        <tbody>
          <tr class="w-full text-sm text-gray-700">
            <td><img src="../../../assets/caribe-tours-logo.png" class="max-h-20"></td>
            <td>{{company.name}}</td>
            <td>{{company.tel}}</td>
            <td>{{company.email}}</td>
            <td>{{company.buses}}</td>
            <td>{{company.status}}</td>
            <td class=" space-x-3">
              <fa-icon icon="edit" class=" hover:bg-warning/20  bg-tertiary/5 text-warning p-2 rounded cursor-pointer"></fa-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
    
  `,
  styles: [`
    .container {
      max-width: 1200px;
    }
  `]
})
export class CompanyPage implements OnInit {



  company = { id: 1, name: 'Caribe tours 1', tel: '123456789', email: 'email1@example.com', buses: 432,  status: 'Activo' }


  constructor() { }

  ngOnInit(): void { }
}
