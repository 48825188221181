import { Component } from '@angular/core';
import { Province } from 'src/models/models';
import { DataService } from '../../services/Data.services';

@Component({
  selector: 'branchOffices-page',
  template: `
    <div class="py-5 2xl:py-10 px-6 md:px-20 2xl:px-28 text-justify">
      <h1 class="label text-2xl 2xl:text-3xl text-center md:text-start">Sucursales</h1>
      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 h-screen pb-44 2xl:pb-60 pt-5 md:pt-2 overflow-y-auto">
        <div 
        (click)="getBranchOfficeInfo(province.id, province.province, province.latitude, province.longitude)"
        routerLink="/branchOffices/province/{{province.id}}/{{province.province}}/{{province.latitude}}/{{province.longitude}}"
        *ngFor="let province of provinces"
        class="rounded-lg cursor-pointer hover:ease-out hover:scale-105 duration-150 py-16 2xl:py-20 my-3 2xl:my-5 object-contain mx-4 relative"
        style="background-image: url('{{province.img}}'); background-size: cover; background-repeat: no-repeat; background-color: black;">
         <div class="bg-black rounded-lg hover:bg-white hover:bg-opacity-10 absolute inset-0 bg-opacity-40">
           <p class="flex items-center justify-center h-full  text-white font-bold text-xl 2xl:text-3xl z-10">{{province.province | titlecase}}</p>
           </div>
          </div>
      </div>
    </div>
  `,
  styles: []
})
export class BranchOfficesPage {
  provinces: Province[] = []
  constructor(private data: DataService){
  }
  getBranchOfficeInfo(id: number, name: string, latitude: number, longitude: number) {
    console.log(id)
  }
}