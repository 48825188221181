import { Component } from '@angular/core';

@Component({
  selector: 'paymentMethod-page',
  template: `
   <div class="py-5 2xl:py-10 px-4 md:px-20 2xl:px-28 text-center md:text-justify">
      <h1 class="label text-2xl 2xl:text-3xl ml-12 md:ml-5 mt-4">Tarjetas de débito / crédito</h1>
      <div class="grid grid-rows-1 md:grid-cols-2 xl:grid-cols-3 mx-4 w-full overflow-x-auto">
      <div class="creditCard">
      <div class="creditCardInfo">
                <i class="fa-brands fa-cc-visa text-3xl 2xl:text-4xl ml-3"></i>
                <p class="text-xl md:text-[16px] 2xl:text-2xl">8520  <span class="mx-2"></span>6543   
                <span class="mx-2"></span>6483 <span class="mx-2"></span>0987</p>
            </div>
        </div>
        <div class="hidden md:block creditCard">
        <div class="creditCardInfo">
                <i class="fa-brands fa-cc-visa text-3xl 2xl:text-4xl ml-3"></i>
                <p class="text-xl md:text-[16px] 2xl:text-2xl">8520  <span class="mx-2"></span>6543   
                <span class="mx-2"></span>6483 <span class="mx-2"></span>0987</p>
            </div>
        </div>
        <div class="hidden xl:block creditCard">
            <div class="creditCardInfo">
                <i class="fa-brands fa-cc-visa text-3xl 2xl:text-4xl ml-3"></i>
                <p class="text-xl md:text-[16px] 2xl:text-2xl">8520  <span class="mx-2"></span>6543   
                <span class="mx-2"></span>6483 <span class="mx-2"></span>0987</p>
            </div>
        </div>
      </div>
      <button class="rounded-full bg-primary text-white text-sm md:text-sm 2xl:text-[16px] py-3 px-8 md:py-3 md:px-4  2xl:py-4 2xl:px-8 font-semibold  my-2" (click)="addCard = true;"><i class="fa-solid fa-plus mr-2"></i> Agregar tarjetas de crédito / débito</button>
      <h1 class="label mt-12 text-2xl 2xl:text-3xl w-full text-start">Monedero virtual</h1>
      <div class="flex flex-col shadow-lg shadow-primary/20 border border-primary bg-white mx-4 md:mx-0 w-11/12 xl:w-3/5 2xl:w-1/2  text-primary text-sm 2xl:text-lg mt-6 p-5 rounded-lg">
       <p class="text-2xl ml-7 font-bold w-full text-start">DOP 0.00</p>
       <button (click)="addFunds = true;" class="rounded-full bg-primary text-white text-sm md:text-sm 2xl:text-[16px] py-3 md:py-3 md:px-4 px-2 2xl:py-4 2xl:px-8 font-semibold  my-2 mt-5 ml-2 text-center w-2/3 md:w-2/6"><i class="fa-solid fa-plus mr-2"></i> Agregar fondos</button>
      </div>
      <modal-component class="z-40" *ngIf="addCard">
    <div class="flex flex-col rounded-xl h-auto w-auto 2xl:w-auto bg-white text-center p-3 shadow-lg shadow-primary/40">
    <i (click)="addCard = false;" class="fa-solid fa-xmark cursor-pointer text-end text-2xl text-primary"></i>
    <div>
        <h1 class="text-xl 2xl:text-2xl font-semibold my-1 mb-2 2xl:mb-3 text-primary">Agregar tarjeta</h1>
        <div class="grid grid-cols-2 items-center my-1 ml-0 text-sm 2xl:text-lg">
            <div class="grid col-span-2 text-start mx-5 my-3">
              <label for="cardNumber my-2">Número de tarjeta</label>
                <input type="number" class="input" placeholder="0000 0000 0000 0000">
            </div>
            <div class="grid col-span-2 md:col-span-1 text-start mx-6 my-3 ">
              <label for="cardNumber my-2">Fecha de vto.</label>
                <input type="number" class="input" placeholder="MM/AA">
            </div>
            <div class="grid col-span-2 md:col-span-1 text-start mx-6 my-3 ">
              <label for="cardNumber my-2">CCV</label>
                <input type="number" class="input" placeholder="123">
            </div>
            
          </div>
          <div class="flex w-full items-center text-center mx-6 mt-3 text-gray-400">
            <input type="checkbox" name="default" id="default" class="cursor-pointer p-2">
            <label for="default" class="ml-2 cursor-pointer">Establecer como predeterminado</label>
          </div>
        <button (click)="addCard = false;" class="button mb-5">Agregar</button>
    </div>
    </div>
    </modal-component>

    <modal-component class="z-40" *ngIf="addFunds">
    <div class="flex flex-col rounded-xl h-auto w-auto 2xl:w-auto p-5 bg-white text-center shadow-lg shadow-primary/40">
    <i (click)="addFunds = false;" class="fa-solid fa-xmark cursor-pointer text-end text-2xl text-primary"></i>
    <div>
        <h1 class="text-xl 2xl:text-2xl font-semibold my-1 mb-2 2xl:mb-3 text-primary">Agregar fondos</h1>
        <div class="grid grid-cols-2 items-center my-1 ml-0 text-sm 2xl:text-lg">
            <div class="grid col-span-2 text-start mx-5 my-3 ">
              <label for="cardNumber my-2">Cantidad a depositar</label>
                <input type="number" class="input" placeholder="Ingrese un monto">
            </div>  
          </div>
          <p class="flex w-full text-start text-lg mx-5  ">Pagar con: </p>
          <div class="flex justify-between text-start mx-6 my-3  ">
            <label for="card" class="font-bold mx-2 flex items-center">
              <i class="fa-brands fa-cc-visa text-2xl mr-2"></i>
              <p class="text-sm">**** 8989</p>
            </label>
              <input type="radio" name="payment" id="card" class="cursor-pointer" value="card">
          </div>
          <div class="flex justify-between text-start mx-6 my-3  ">
            <label for="paypal" class="font-bold mx-2 flex items-center cursor-pointer">
              <i class="fa-brands fa-cc-visa text-2xl mr-2"></i>
              <p class="text-sm">account@paypal.com</p>
            </label>
              <input type="radio" name="payment" id="paypal" class="cursor-pointer" value="paypal">
          </div>
        <button (click)="addFunds = false;" class="button mb-5  ">Agregar</button>
    </div>
    </div>
    </modal-component>
   </div>
  
  `,
  styles: []
})
export class paymentMethodPage {
  addCard = false;
  addFunds = false
}