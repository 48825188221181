import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TicketAvailableList } from 'src/models/models';

@Component({
  selector: 'ticket-component',
  template: `
                          <!-- Ticket para pantallas md, xl, 2xl en adelante -->
    <ng-container *ngIf="ticket">
        <div class="hidden md:flex max-w-full w-[90vw]  xl:w-[55vw] 2xl:w-[43vw] mx-auto mb-10 bg-white shadow-lg border border-gray-100 rounded-lg overflow-hidden">

            <div class="p-4 flex justify-between w-full">
                <div class="flex flex-col justify-between items-center max-w-full">
                        <p class="text-primary font-bold text-sm xl:text-base truncate">
                            <fa-icon icon="bus"></fa-icon>
                            {{ ticket.company.name }}
                        </p>
                        <img src="{{ ticket.company.logo }}" alt="" class="w-32 border shadow-sm border-gray-200 rounded">
                        <div class="flex">
                            <span *ngFor="let i of [0, 1, 2, 4, 5]" class="bg-yellow-500 hover:scale-110 duration-100 cursor-pointer mx-0.5 w-4 h-4" style="clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);"> </span>
                        </div>
                        <!-- <div class="flex flex-col items-center text-sm font-bold text-gray-500 ">
                            <div class="flex justify-between space-x-2 ">
                                <fa-icon icon="wifi"> </fa-icon>
                                <fa-icon icon="toilet"> </fa-icon>
                                <fa-icon icon="snowflake"> </fa-icon>
                            </div>
                        </div> -->
                        <!-- <div class="text-primary text-xs cursor-pointer">Detalles <fa-icon icon="chevron-down"></fa-icon></div> -->
                </div>
                
                <div class="flex flex-col justify-between bg-gray-100/25 border border-gray-100 w-[55%] max-w-[55%] px-4 py-1 rounded-lg">
                    <div class="flex justify-between items-center text-primary font-bold pt-2 mt-3">
                        <p class="w-1/3 text-info"><fa-icon icon="arrow-up"></fa-icon> Origen </p>
                        <p class="w-1/3">Duración </p>
                        <p class="w-1/3 text-info"><fa-icon icon="arrow-down"></fa-icon> Destino </p>
                    </div>
                    <div class="flex justify-between items-center mb-3">
                        <div class="text-center w-1/3">
                            <p class="text-gray-700 font-bold">{{ ticket.origin.description }}</p>
                            <p class="text-sm text-gray-500">{{ ticket.departure_time }}</p>
                        </div>
                        <div class="text-center w-1/3 text-gray-500">
                            <fa-icon icon="clock" class="text-gray-700"></fa-icon> 
                            <p class="text-sm text-gray-500">{{ ticket.estimated_duration }}</p>
                        </div>
                        <div class="text-center w-1/3">
                            <p class="text-gray-700 font-bold">{{ ticket.destiny.description }}</p>
                            <p class="text-sm text-gray-500">{{ ticket.arribal_time }}</p>
                        </div>
                    </div>
                </div>
                
                <div class="text-center mt-4 space-y-2">
                <p class="text-gray-700 font-bold text-2xl">$ {{ticket.price }}<span class="text-sm">.00</span></p>
                    <button class="button primary shine-effect text-white px-6 py-2 rounded-lg mr-2" (click)="acquireTicket(ticket)">Adquirir <fa-icon icon="chevron-right"></fa-icon></button>
                    <p class="text-xs text-success" *ngIf="ticket.seats_available">$ {{ticket.seats_available}} {{ticket.seats_available > 1 ? 'Asientos' : 'Asiento'}} <br> {{ticket.seats_available > 1 ? 'disponibles' : 'disponible'}}</p>
                    </div>
            </div>
        </div>
    </ng-container>

    <!-- Ticket para mobile -->
    <ng-container *ngIf="ticket">
        <div class="md:hidden w-[90vw] mx-4 my-5 bg-white shadow-lg border border-gray-100 rounded-lg overflow-hidden">

            <div class="flex flex-col justify-between">
                <!--Left-->
                <div class="flex justify-between items-center p-3">
                    <img src="{{ ticket.company.logo }}" alt="" class="w-24 border shadow-sm border-gray-200 rounded">
                    <div class="flex flex-col items-center">
                        <p class="text-primary font-bold">
                            <fa-icon icon="bus"></fa-icon>
                            {{ ticket.company.name }}
                        </p>
                        <div class="flex">
                            <span *ngFor="let i of [0, 1, 2, 4, 5]" class="bg-yellow-500 hover:scale-110 duration-100 cursor-pointer mx-0.5 w-4 h-4" style="clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);"> </span>
                        </div>
                    </div>
                </div>   
                <div class="flex flex-col justify-between  w-full border-y-2 border-dashed border-primary/10 p-2">
                    <div class="flex justify-between items-center text-primary font-bold pt-2">
                        <p class="w-1/3 text-info"><fa-icon icon="arrow-up"></fa-icon> Origen </p>
                        <p class="w-1/3">Duración </p>
                        <p class="w-1/3 text-info"><fa-icon icon="arrow-down"></fa-icon> Destino </p>
                    </div>
                    <div class="flex justify-between items-center">
                        <div class="text-center w-1/3">
                            <p class="text-gray-700 font-bold">{{ ticket.origin.description }}</p>
                            <p class="text-sm text-gray-500">{{ ticket.departure_time }}</p>
                        </div>
                        <div class="text-center w-1/3 text-gray-500">
                            <fa-icon icon="clock" class="text-gray-700"></fa-icon> 
                            <p class="text-sm text-gray-500">{{ ticket.estimated_duration }}</p>
                        </div>
                        <div class="text-center w-1/3">
                            <p class="text-gray-700 font-bold">{{ ticket.destiny.description }}</p>
                            <p class="text-sm text-gray-500">{{ ticket.arribal_time }}</p>
                        </div>
                    </div>
                </div>
    
    
                <div class="flex justify-between items-center p-3">
                <p class="text-gray-700 font-bold text-3xl">$ {{ticket.price }}<span class="text-sm">.00</span></p>
                <p class="text-xs text-success" *ngIf="ticket.seats_available">$ {{ticket.seats_available}} {{ticket.seats_available > 1 ? 'Asientos' : 'Asiento'}} <br> {{ticket.seats_available > 1 ? 'disponibles' : 'disponible'}}</p>
                <button class="button primary shine-effect text-white px-6 py-2 rounded-lg mr-2" (click)="acquireTicket(ticket)">Adquirir</button>
                </div>
            </div>
        </div>
</ng-container>

`,
  styles: []
})
export class TicketComponent {
@Input() ticket: TicketAvailableList | undefined; 
@Output() book_ticket = new EventEmitter<TicketAvailableList>();
  acquireTicket(selected_ticket: TicketAvailableList) {
      this.book_ticket.emit(selected_ticket);
    }
}